
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './DataViewer.css'; // スタイルのために追加
import { SelectTheme } from '../../components/theme/SelectTheme';
import { Main, Drawer, BootstrapInput } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { AppBar } from '../../components/components/AppBar';
import { SelectDate } from '../../components/components/select-date';
import downloadWhite from '../../assets/images/download-white.png';


// MUI @mui/material/
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

// MUI @mui/icons-material/
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Select Date
import dayjs from "dayjs";

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}


export default function VesselList({ setSeachInfo }) {

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();



  React.useEffect(() => {
    if (init === true) {

      // localStrage取得----------------------------------------------------------------------------------------------------------------------------
      let jwt_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          jwt_ls = pay["jwt"]
          setUser_id(pay["user_id"])
          setGroup_id(pay["group_id"])
          setCompany_id(pay["company_id"])
          setGid(pay["gid"])
          setGidList(pay["gidList"])
          setImoList(pay["imoList"])
          setJwt(jwt_ls)
          setRole(pay["role"])
          setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: pay["gid"], gidList: pay["gidList"], imoList: pay["imoList"] })
        }
      }

      if (jwt_ls) {
        callAuth(jwt_ls).then(result => {
          if (result) {
            console.log("[DataViewer]認証成功");
            // Loading解除
            setLoading(false);
            setSuccess(true);
          } else {
            console.log("[DataViewer]認証失敗");
            setUserCheck(true)
            setMessage("認証エラー")
            localStorage.clear()
          }
        });
      }
    }

    init = false
  }, []);

  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
  };

  // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);


  // 検索フォーム----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}DataViewer/`);
  // const [base_url, setBase_url] = React.useState(`${BaseUrl}/DataViewer/`);


  // Select Imo----------------------------------------------------------------------------------------------------------------------------
  // const [Imo, setImo] = React.useState<string>("");
  const selectImo = (event: SelectChangeEvent) => {
    // setImo(event.target.value as string);
    setSeachInfo({ imoNo: `${event.target.value as string}`, from: SeachInfo.from, to: SeachInfo.to, gid: gid, gidList: gidList, imoList: imoList })
  };

  // Drawer----------------------------------------------------------------------------------------------------------------------------
  const [openDrwer, setOpenDrwer] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpenDrwer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrwer(false);
  };

  // DataRnage----------------------------------------------------------------------------------------------------------------------------
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  // VesselList----------------------------------------------------------------------------------------------------------------------------
  const csvHeader = {
    // "local_date": "local_date", 
    // "timestamp": "timestamp", 
    // "state": "state", 
    // "port_name": "port_name", 
    // "latitude": "latitude", 
    // "longitude": "longitude", 
    // "voyage_no": "voyage_no", 
    // "leg_no": "leg_no", 
    // "leg": "leg", 
    // "co2_factor": "co2_factor", 
    // "course": "course", 
    // "beaufort": "beaufort", 
    // "log_distance": "log_distance", 
    // "og_distance": "og_distance", 
    // "log_speed": "log_speed", 
    // "og_speed": "og_speed", 
    // "me_rpm": "me_rpm", 
    // "ge_foc": "ge_foc", 
    // "boiler_foc": "boiler_foc", 
    // "me_foc": "me_foc", 
    // "total_foc": "total_foc", 
    // "me_load": "me_load", 
    // "eta_local_date": "eta_local_date", 
    // "eta_utc_date": "eta_utc_date", 
    // "eta_destination": "eta_destination", 
    // "displacement": "displacement", 
    // "gt": "gt", 
    // "dwt": "dwt", 
    // "wind_speed": "wind_speed", 
    // "wind_direction": "wind_direction", 
    // "wave_period": "wave_period", 
    // "wave_direction": "wave_direction", 
    // "wave_height": "wave_height", 
    // "swell_height": "swell_height", 
    // "swell_period": "swell_period", 
    // "swell_direction": "swell_direction", 
    // "ablog_id": "ablog_id",
    "Local Date": "Local Date",
    "UTC Date": "UTC Date",
    "State": "State",
    "Port Name": "Port Name",
    "Latitude": "Latitude",
    "Longitude": "Longitude",
    "Voyage No": "Voyage No",
    "Leg No": "Leg No",
    "Leg": "Leg",
    "FO Equiv": "FO Equiv",
    "Course": "Course",
    "Beaufort": "Beaufort",
    "LOG Distance": "LOG Distance",
    "OG Distance": "OG Distance",
    "LOG Speed": "LOG Speed",
    "OG Speed": "OG Speed",
    "M/E Load": "M/E Load",
    "M/E RPM": "M/E RPM",
    "M/E FOC": "M/E FOC",
    "GE FOC": "GE FOC",
    "BOILER FOC": "BOILER FOC",
    "FOC": "FOC",
    "ETA Local Date": "ETA Local Date",
    "ETA UTC Date": "ETA UTC Date",
    "ETA Destination": "ETA Destination",
    "Displacement": "Displacement",
    "GT": "GT",
    "DWT": "DWT",
    "Wind Speed": "Wind Speed",
    "Wind Direction": "Wind Wind Direction",
    "Wave Height": "Wave Height",
    "Wave Period": "Wave Period",
    "Wave Direction": "Wave Direction",
    "Swell Height": "Swell Height",
    "Swell Period": "Swell Period",
    "Swell Direction": "Swell Direction",
    "Ablog ID": "Ablog ID",
  }
  const init_row = {
    "local_date": "",
    "utc_date": "",
    "state": "IN PORT",
    "port_name": "",
    "lat": "",
    "lng": "",
    "voyage_no": "",
    "leg_no": "",
    "leg": "",
    "co2_factor": "",
    "course": "",
    "beaufort": "",
    "log_distance": "",
    "og_distance": "",
    "log_speed": "",
    "og_speed": "",
    "me_load": "",
    "me_rpm": "",
    "me_foc": "",
    "ge_foc": "",
    "boiler_foc": "",
    "total_foc": "",
    "eta_local_date": "",
    "eta_utc_date": "",
    "eta_destination": "",
    "displacement": "",
    "gt": "",
    "dwt": "",
    "wind_speed": "",
    "wind_direction": "",
    "wave_period": "",
    "wave_direction": "",
    "wave_height": "",
    "swell_height": "",
    "swell_period": "",
    "swell_direction": "",
    "ablog_id": "",
    "beaufort_alerm": 0,
    "boiler_foc_alerm": 0,
    "course_alerm": 0,
    "displacement_alerm": 0,
    "dwt_alerm": 0,
    "ge_foc_alerm": 0,
    "gt_alerm": 0,
    "lat_alerm": 0,
    "lng_alerm": 0,
    "log_distance_alerm": 0,
    "log_speed_alerm": 0,
    "me_foc_alerm": 0,
    "me_load_alerm": 0,
    "me_rpm_alerm": 0,
    "og_distance_alerm": 0,
    "og_speed_alerm": 0,
    "swell_direction_alerm": 0,
    "swell_height_alerm": 0,
    "swell_period_alerm": 0,
    "total_foc_alerm": 0,
    "wave_direction_alerm": 0,
    "wave_height_alerm": 0,
    "wave_period_alerm": 0,
    "wind_direction_alerm": 0,
    "wind_speed_alerm": 0,
  }
  const [VesselMaster, setVesselMaster] = React.useState<any>();
  const [rows, setRows] = React.useState([init_row])
  const [ALERMLIST, setALERMLIST] = React.useState({
    "beaufort_alerm_count": "",
    "boiler_foc_alerm_count": "",
    "course_alerm_count": "",
    "displacement_alerm_count": "",
    "dwt_alerm_count": "",
    "ge_foc_alerm_count": "",
    "gt_alerm_count": "",
    "lat_alerm_count": "",
    "lng_alerm_count": "",
    "log_distance_alerm_count": "",
    "log_speed_alerm_count": "",
    "me_foc_alerm_count": "",
    "me_load_alerm_count": "",
    "me_rpm_alerm_count": "",
    "og_distance_alerm_count": "",
    "og_speed_alerm_count": "",
    "swell_direction_alerm_count": "",
    "swell_height_alerm_count": "",
    "swell_period_alerm_count": "",
    "total_foc_alerm_count": "",
    "wave_direction_alerm_count": "",
    "wave_height_alerm_count": "",
    "wave_period_alerm_count": "",
    "wind_direction_alerm_count": "",
    "wind_speed_alerm_count": "",
  })

  // CSVダウンロード---------------------------------------------------------------------------------------------------------------------------
  const [openDialogDownloadCSV, setOpenDialogDownloadCSV] = React.useState(false);
  const handleClickOpenDialogDownloadCSV = () => {
    setOpenDialogDownloadCSV(true)
  }
  // Cancelボタン
  const handleNoCloseDialogDownloadCSV = () => {
    setOpenDialogDownloadCSV(false)
  }
  // リストをCSV形式の文字列に変換する関数
  const convertToCSV = (data) => {
    const csv = data.map(row => Object.values(row).join(',')).join('\n');
    console.log(csv)
    return csv;
  }
  // CSVファイルをダウンロードする関数
  const handleCloseDialogDownloadCSV = () => {
    if (rows.length > 0 && rows[0].utc_date != "") {
      const newItem = [csvHeader, ...rows]
      console.log(newItem)
      const csv = convertToCSV(newItem);
      const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = window.URL.createObjectURL(csvBlob);
      const tempLink = document.createElement('a');
      tempLink.href = csvUrl;
      const newDate = new Date()
      const csvPath = 'ECO_Metrics_' + newDate.toISOString() + '.csv'
      tempLink.setAttribute('download', csvPath);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setOpenDialogDownloadCSV(false)
    }
    else {
      alert("対象レコードがありません")
    }
  }


  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);


  // 時系列データ取得--------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");

  const handleClickSearch = () => {
    setLoading(true)
    setSuccess(false)
    getNoonReport();
  };

  // Seach実行
  const getNoonReport = () => {

    if (SeachInfo.imoNo !== "" && SeachInfo.from !== null && SeachInfo.to !== null) {

      const options = {
        headers: {
          'Authorization': jwt,
        }
      }
      const url = `${base_url}${SeachInfo.imoNo}?Timestamp_from=${SeachInfo.from}&Timestamp_to=${SeachInfo.to}`
      console.log("url:", url);
      axios.get(url, options).then((res) => {
        console.log("axios", res)

        // 認可チェック
        if (res.data === 401) {
          setAuthCheck(true)
          setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          setAuthCheck(true)
          setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          setAuthCheck(false)
        }

        // 入力値保持
        setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: gid, gidList: gidList, imoList: imoList })

        if (res.data.datas !== "") {
          setRows(res.data.datas.TIMESERIES)
          setALERMLIST(res.data.datas.ALERMLIST)
          setVesselMaster(res.data.datas.VESSELMATSER)
        }

        // Loading解除
        setLoading(false);
        setSuccess(true);
      })
        .catch((e: AxiosError<ErrorData>) => {
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

  // 複数テーブルのスクロール連動
  const bottomRightAreaRef = React.useRef<HTMLDivElement>(null);
  const bottomLeftAreaRef = React.useRef<HTMLDivElement>(null);
  const topRightAreaRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop, scrollLeft } = e.target;

      if (bottomLeftAreaRef.current) {
        bottomLeftAreaRef.current.scrollTop = scrollTop;
      }

      if (topRightAreaRef.current) {
        topRightAreaRef.current.scrollLeft = scrollLeft;
      }
    };

    if (bottomRightAreaRef.current) {
      bottomRightAreaRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (bottomRightAreaRef.current) {
        bottomRightAreaRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);


  // サイドバー展開--------------------------------------------------------------------
  // 現在のアクティブなタブを管理するための状態
  const [SeachTab, setSeachTab] = React.useState<boolean>(true);
  const [VesselInformationTab, setVesselInformationTab] = React.useState<boolean>(true);

  // タブがクリックされた時に呼び出されるハンドラー関数
  const handleTabClick = (index) => {
    console.log("handleTabClick", index)
    if (index === 1) {
      setSeachTab(!SeachTab)
    }
    else if (index === 2) {
      setVesselInformationTab(!VesselInformationTab)
    }
  };


  // ヘッダーセルコンポーネント
  const HeaderCell = (props) => {
    return (
      <>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            minWidth: props.width,
            maxWidth: props.width,
            minHeight: "25px",
            maxHeight: "25px",
            color: "rgb(255,255,255,0.8)",
            whiteSpace: "nowrap",
            fontSize: "13px",
          }}>
          {props.name}
        </Stack >
      </>
    );
  }

  const HeaderCellAlerm = (props) => {
    return (
      <>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            minWidth: props.width,
            maxWidth: props.width,
            minHeight: "25px",
            maxHeight: "25px",
            color: "rgb(255,255,255,0.8)",
            whiteSpace: "nowrap",
            fontSize: "13px",
          }}>
          {props.name}
          <Box sx={{ marginTop: "4px", marginLeft: "4px" }}>
            {
              props.alerm === 0 || props.alerm === "" ?
                ""
                :
                <Tooltip
                  title={props.alerm}
                  placement='top'
                  arrow
                >
                  <ReportProblemIcon
                    sx={{
                      height: "16px",
                      width: "16px",
                      color: "yellow",
                      ":hover": { color: "orange" },
                      cursor: "pointer"
                    }} />
                </Tooltip>
            }
          </Box>
        </Stack >
      </>
    );
  }


  return (
    <Box height="100vh" sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* useId有効期限切れダイアログ */}
      <Dialog
        open={userCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",  // Set your width here
              height: "100%",
              maxHeight: "210px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          UserId Update
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
            height: "100px",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {message === "" ? "" : message}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            height: "50px",
            bgcolor: "#474C53",
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "150px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              borderBottomRightRadius: "2px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleClickWADATSUMI}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* 認可チェックダイアログ */}
      <Dialog
        open={authCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",  // Set your width here
              height: "100%",
              maxHeight: "210px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          Auth check
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
            height: "100px",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {authMessage === "" ? "" : authMessage}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            height: "50px",
            bgcolor: "#474C53",
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "150px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              borderBottomRightRadius: "2px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={() => setAuthCheck(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


      {/* menu bar */}
      <AppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />

      {/* side bar */}
      <Drawer
        variant="permanent"
        open={openDrwer}
        sx={{
          // background: 'linear-gradient(180deg, #3B3D43 0%, #383A40 100%)',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            background: '#282F35',
            boxSizing: 'border-box',
            borderRight: "1px solid rgb(201,201,201,0.2)"
          },
        }}>
        <React.Fragment>
          {/* 検索フォーム開閉 */}
          {openDrwer ?
            <Box sx={{ marginTop: "64px" }}>

              {/* ↓ Space ↓ */}
              <Stack alignItems="center" sx={{ position: "relative", height: "20px" }}>
              </Stack>
              {/* ↑ Space ↑ */}

              {/* ↓ Select Search Conditions ↓ */}
              <Stack alignItems="left" sx={{ position: "relative" }}>
                {
                  SeachTab !== true ?
                    <>
                      {/* title */}
                      <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(1)}>
                        <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                          <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                        </Box>
                        <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                          <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                            Select Search Conditions
                          </Typography>
                        </Box>
                      </Stack>
                    </>
                    :
                    <>
                      {/* title */}
                      <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(1)}>
                        <Box sx={{ position: "absolute", top: "11px", left: "25px", }}>
                          <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                        </Box>
                        <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                          <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                            Select Search Conditions
                          </Typography>
                        </Box>
                      </Stack>

                      {/* main */}
                      <Stack sx={{ position: "relative", height: "330px", width: `calc(100% - 22px)` }}>

                        {/* Vessel Name */}
                        <Stack alignItems="center" sx={{ position: "relative", top: "10px", left: "22px", height: "120px" }}>
                          <Typography
                            align="left"
                            sx={{
                              position: "absolute",
                              top: "0px",
                              left: "20px",
                              color: 'white',
                              fontSize: "14px",
                              fontFamily: "'Roboto Bold'",
                              fontWeight: '400',
                              lineHeight: "24px",
                              wordWrap: 'break-word',
                            }}>
                            Vessel Name
                          </Typography>
                          <ThemeProvider theme={SelectTheme}>
                            <FormControl
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "20px",
                              }}>
                              <Select
                                IconComponent={() =>
                                  <div style={{ position: "absolute", top: "20px", left: "235px" }}>
                                    <InputAdornment position="start" disablePointerEvents>
                                      <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                    </InputAdornment>
                                  </div>
                                }
                                defaultValue={SeachInfo.imoNo}
                                MenuProps={{
                                  MenuListProps: {
                                    disablePadding: true,
                                  },
                                  style: { zIndex: 100000, }
                                }}
                                sx={{
                                  width: "265px",
                                  height: 45,
                                  color: "white",
                                  ":hover": { backgroundColor: "#7a86a0" },
                                  borderRadius: 30,
                                }}
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                label="range"
                                input={<BootstrapInput />}
                                onChange={selectImo}
                                value={SeachInfo.imoNo}>
                                {SeachInfo.imoList?.map((imo) => {
                                  return (
                                    <MenuItem value={imo.imoNo}>{imo.VesselName}</MenuItem>
                                  )
                                }
                                )}
                              </Select>
                            </FormControl>
                          </ThemeProvider>
                        </Stack>

                        {/* Data Range */}
                        <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "120px" }}>
                          <Typography
                            align="left"
                            sx={{
                              position: "absolute",
                              top: "0px",
                              left: "20px",
                              color: 'white',
                              fontSize: "14px",
                              fontFamily: "'Roboto Bold'",
                              fontWeight: '400',
                              lineHeight: "24px",
                              wordWrap: 'break-word',
                            }}>
                            Data Range
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={handleClickOpenDialog}
                            sx={{
                              position: "absolute",
                              top: "30px",
                              left: "20px",
                              width: "265px",
                              height: 45,
                              background: 'rgba(255, 255, 255, 0.15)',
                              borderRadius: 30,
                              border: '1px #CBCBCB solid',
                              color: 'white',
                              fontSize: 15,
                              fontFamily: 'Roboto',
                              fontWeight: '700',
                              wordWrap: 'break-word',
                              ":hover": { backgroundColor: "#7a86a0", border: '1px #CBCBCB solid' },
                            }}>
                            <Stack direction='row' sx={{ height: 20 }} alignItems="center" justifyContent="center">
                              <CalendarMonthIcon />
                            </Stack>
                            <Typography align="left" sx={{ color: 'white', width: "100%", fontSize: "15px", marginLeft: 2 }}>
                              {dayjs(SeachInfo.from).utc().year() + "/" + (dayjs(SeachInfo.from).utc().month() + 1) + "/" + dayjs(SeachInfo.from).utc().date()}
                              ~
                              {dayjs(SeachInfo.to).utc().year() + "/" + (dayjs(SeachInfo.to).utc().month() + 1) + "/" + dayjs(SeachInfo.to).utc().date()}
                            </Typography>
                          </Button>
                        </Stack>
                        <Box >{<SelectDate setSeachInfo={setSeachInfo} SeachInfo={SeachInfo} openDialog={openDialog} setOpenDialog={setOpenDialog} />}</Box>


                        {/* Search */}
                        <Stack sx={{ position: "relative" }}>
                          <Button
                            variant="contained"
                            onClick={handleClickSearch}
                            sx={{
                              position: "absolute",
                              top: "30px",
                              left: "65px",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40px",
                              width: 200,
                              background: '#E66300',
                              borderRadius: 30,
                              color: "#fff",
                              ":hover": {
                                bgcolor: "#FFFFFF",
                                color: "#40475D",
                              },
                              textTransform: 'none'
                            }}
                          >
                            <SearchIcon sx={{ marginRight: "4px", width: "20px", height: "20px" }} />
                            <Typography align="center" sx={{ fontSize: 15 }}>Search</Typography>
                          </Button>
                        </Stack>
                      </Stack>
                    </>
                }
              </Stack>
              {/* ↑ Select Search Conditions ↑ */}

              {/* ↓ Border ↓ */}
              <Stack alignItems="center" sx={{ position: "relative", height: "60px" }}>
                <Box sx={{ position: "absolute", top: "30px", width: `80%`, border: '1px rgba(255, 255, 255, 0.30) dotted' }}></Box>
              </Stack>
              {/* ↑ Border ↑ */}

              {/* ↓ VesselInformation ↓ */}
              <Stack alignItems="left" sx={{ position: "relative" }}>
                {
                  VesselInformationTab !== true ?
                    <>
                      {/* title */}
                      <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(2)}>
                        <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                          <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                        </Box>
                        <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                          <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                            Vessel Information
                          </Typography>
                        </Box>
                      </Stack>
                    </>
                    :
                    <>
                      {/* title */}
                      <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(2)}>
                        <Box sx={{ position: "absolute", top: "12px", left: "25px", }}>
                          <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                        </Box>
                        <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                          <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                            Vessel Information
                          </Typography>
                        </Box>
                      </Stack>

                      {/* main */}
                      {success ?

                        <Stack sx={{ position: "relative", height: "330px", width: `calc(100% - 22px)` }}>
                          <Grid container direction="row" sx={{ position: "absolute", left: "22px", top: "0px" }}>

                            {/* Vessel Type */}
                            <Grid item xs={12}>
                              <Grid container direction="row" >
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    Vessel Type
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    :
                                  </Box>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                    {VesselMaster ? VesselMaster.VesselType : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {/* Fuel Type */}
                            <Grid item xs={12}>
                              <Grid container direction="row" >
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    Fuel Type
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    :
                                  </Box>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                    {VesselMaster ? VesselMaster.OilType : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {/* Segmental */}
                            <Grid item xs={12}>
                              <Grid container direction="row" >
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    Segmental
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    :
                                  </Box>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                    {VesselMaster ? VesselMaster.Size : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {/* DeadWeight */}
                            <Grid item xs={12}>
                              <Grid container direction="row" alignItems={"center"}>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    DeadWeight
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    :
                                  </Box>
                                </Grid>
                                <Grid item xs={2.5}>
                                  <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                    {VesselMaster ? VesselMaster.Deadweight : ""}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                  <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                    {VesselMaster ? "ton" : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {/* Gross */}
                            <Grid item xs={12}>
                              <Grid container direction="row" alignItems={"center"} >
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    Gross
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    :
                                  </Box>
                                </Grid>
                                <Grid item xs={2.5}>
                                  <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                    {VesselMaster ? VesselMaster.Grosstongue : ""}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                  <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                    {VesselMaster ? "ton" : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {/* Built Date */}
                            <Grid item xs={12}>
                              <Grid container direction="row" >
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    Built Date
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    :
                                  </Box>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                    {VesselMaster ? VesselMaster.BuildDate : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {/* Hull Cleaning Date */}
                            <Grid item xs={12}>
                              <Grid container direction="row" >
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    Hull Cleaning Date
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    :
                                  </Box>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                    {VesselMaster ? VesselMaster.HullCleaningDate : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {/* Last Dock Date */}
                            <Grid item xs={12}>
                              <Grid container direction="row" >
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    Last Dock Date
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                    :
                                  </Box>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                    {VesselMaster ? VesselMaster.LastDockDate : ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Stack>
                        :

                        <Stack sx={{ position: "relative", height: "330px", width: `${300 - 20}px` }}>
                          {loading && (
                            <CircularProgress
                              size={68}
                              sx={{
                                color: "grey",
                                position: 'absolute',
                                top: "100px",
                                left: "100px",
                                zIndex: 1,
                              }}
                            />
                          )}
                        </Stack>
                      }
                    </>
                }
              </Stack>
              {/* ↑ VesselInformation ↑ */}

            </Box>
            :
            <Box>
            </Box>
          }
        </React.Fragment >
      </Drawer >

      {/* メイン部分 */}
      <Main>

        {/* ↓ OpenClose ↓ */}
        <Stack
          sx={{
            position: "absolute", top: "64px", left: `${openDrwer ? "345px" : "0px"}`, width: '10px', height: '20px',
            background: 'rgba(255, 255, 255, 0.25)',
            cursor: "pointer",
            transition: "0.5s",
          }}
          onClick={openDrwer ? handleDrawerClose : handleDrawerOpen}>
          <Box sx={{ position: "absolute", top: "7px", left: "2px", width: "6px", height: "6px", borderTop: "solid 2px #01103B", borderRight: "solid 2px #01103B", transform: `${openDrwer ? "rotate(225deg)}" : "rotate(45deg)}"}` }}></Box>
        </Stack>
        {/* ↑ OpenClose ↑ */}

        {/* CSVダウンロードダイアログ */}
        <Dialog
          open={openDialogDownloadCSV}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",
                height: "100%",
                maxHeight: "240px",
              },
            },
          }}
        >
          <DialogTitle
            sx={{
              bgcolor: "#474C53",
              height: "50px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            Download CSV
            <IconButton
              aria-label="close"
              onClick={handleNoCloseDialogDownloadCSV}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[1000],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
            }}>
            <Box sx={{
              position: 'absolute',
              color: "white",
              left: "110px",
              top: "80px",
            }}>
              Data is exported as a csv file.
            </Box>
            <Box sx={{
              position: 'absolute',
              color: "white",
              left: "140px",
              top: "120px",
            }}>
              <Button
                sx={{
                  backgroundColor: "#e66300",
                  width: "140px",
                  height: "40px",
                  fontSize: "14px",
                  fontFamily: "Roboto Bold",
                  fontWeight: "bold",
                  color: "white",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                  ":hover": {
                    bgcolor: "#FFFFFF",
                    color: "#40475D",
                  },
                  textTransform: 'none'
                }}
                onClick={handleCloseDialogDownloadCSV}
              >
                <img
                  src={downloadWhite}
                  alt="logo"
                  className="download-csv"
                />
                Download
              </Button>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              height: "50px",
              bgcolor: "#474C53"
            }}>
            <Button
              sx={{
                marginLeft: "0px",
                marginRight: "-8px",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                width: "127px",
                height: "50px",
                padding: "0",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "white",
                borderRadius: "0px",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              onClick={handleNoCloseDialogDownloadCSV}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* CSVダウンロードボタン */}
        <Stack direction="row" alignItems={"left"} >
          <Box sx={{ position: "relative" }}>

            <Button
              disabled={rows.length > 0 && rows[0]?.utc_date !== "" ? false : true}
              sx={{
                opacity: `${rows.length > 0 && rows[0]?.utc_date !== "" ? 1 : 0.3}`,
                position: "absolute",
                top: "40px",
                left: "1400px",
                marginLeft: "0px",
                marginRight: "-8px",
                backgroundColor: "#e66300",
                width: "100px",
                height: "30px",
                padding: "0",
                fontSize: "12px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "white",
                borderBottomRightRadius: "15px",
                borderBottomLeftRadius: "15px",
                borderTopRightRadius: "15px",
                borderTopLeftRadius: "15px",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              onClick={() => handleClickOpenDialogDownloadCSV()}
            >
              Download
            </Button>
          </Box>
        </Stack>

        {/* Table */}
        <div id="DataViewer-fixedTableArea">

          {/* Header */}
          <div id="DataViewer-topLeftArea">
            <table id="DataViewer-topLeftTable" className="fixedTable">
              <tbody>
                <tr>
                  <td><HeaderCell name={"Local Date"} width={"150px"} /></td>
                  <td><HeaderCell name={"UTC Date"} width={"150px"} /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="DataViewer-topRightArea" ref={topRightAreaRef}>
            <table id="DataViewer-topRightTable" className="fixedTable" >
              <tbody>
                <tr>
                  <td><HeaderCell name={"State"} width={"150px"} /></td>
                  <td><HeaderCell name={"Port Name"} width={"150px"} /></td>
                  <td><HeaderCellAlerm name={"Latitude"} width={"150px"} alerm={ALERMLIST.lat_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Longitude"} width={"150px"} alerm={ALERMLIST.lng_alerm_count} /></td>
                  <td><HeaderCell name={"Voyage No"} width={"150px"} /></td>
                  <td><HeaderCell name={"Leg No"} width={"150px"} /></td>
                  <td><HeaderCell name={"Leg"} width={"150px"} /></td>
                  <td><HeaderCell name={"FO Equiv."} width={"150px"} /></td>
                  <td><HeaderCellAlerm name={"Course"} width={"150px"} alerm={ALERMLIST.course_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Beaufort"} width={"150px"} alerm={ALERMLIST.beaufort_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"LOG Distance"} width={"150px"} alerm={ALERMLIST.log_distance_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"OG Distance"} width={"150px"} alerm={ALERMLIST.og_distance_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"LOG Speed"} width={"150px"} alerm={ALERMLIST.log_speed_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"OG Speed"} width={"150px"} alerm={ALERMLIST.og_speed_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"M/E Load"} width={"150px"} alerm={ALERMLIST.me_load_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"M/E RPM"} width={"150px"} alerm={ALERMLIST.me_rpm_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"M/E FOC"} width={"150px"} alerm={ALERMLIST.me_foc_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"GE FOC"} width={"150px"} alerm={ALERMLIST.ge_foc_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"BOILER FOC"} width={"150px"} alerm={ALERMLIST.boiler_foc_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"FOC"} width={"150px"} alerm={ALERMLIST.total_foc_alerm_count} /></td>
                  <td><HeaderCell name={"ETA Local Date"} width={"150px"} /></td>
                  <td><HeaderCell name={"ETA UTC Date"} width={"150px"} /></td>
                  <td><HeaderCell name={"ETA Destination"} width={"150px"} /></td>
                  <td><HeaderCellAlerm name={"Displacement"} width={"150px"} alerm={ALERMLIST.displacement_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"GT"} width={"150px"} alerm={ALERMLIST.gt_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"DWT"} width={"150px"} alerm={ALERMLIST.dwt_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Wind Speed"} width={"150px"} alerm={ALERMLIST.wind_speed_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Wind Direction"} width={"150px"} alerm={ALERMLIST.wind_direction_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Wave Height"} width={"150px"} alerm={ALERMLIST.wave_height_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Wave Period"} width={"150px"} alerm={ALERMLIST.wave_period_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Wave Direction"} width={"150px"} alerm={ALERMLIST.wave_direction_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Swell Height"} width={"150px"} alerm={ALERMLIST.swell_height_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Swell Period"} width={"150px"} alerm={ALERMLIST.swell_period_alerm_count} /></td>
                  <td><HeaderCellAlerm name={"Swell Direction"} width={"150px"} alerm={ALERMLIST.swell_direction_alerm_count} /></td>
                  <td><HeaderCell name={"Ablog ID"} width={"150px"} /></td>

                  <td className="dummyColumn"></td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Header */}

          {/* Body */}
          <div id="DataViewer-bottomLeftArea" ref={bottomLeftAreaRef}>
            <table id="DataViewer-bottomLeftTable" className="fixedTable">
              {success ?

                <tbody>
                  {rows[0]?.utc_date !== "" ?
                    <>
                      {
                        rows.map((row, index) => {
                          let local_date_show = ""

                          if (row.local_date !== "") {
                            // 日付を取得する
                            console.log(row.local_date)
                            const defDate = new Date(row.local_date)

                            // UTCに変換する
                            const utcDatetime = defDate.toISOString();

                            // UTCから日付情報を取得する
                            const dateParts = utcDatetime.split('T')[0].split('-');
                            const timeParts = utcDatetime.split('T')[1].split('.')[0].split(':');

                            // フォーマットに従って日付文字列を組み立てる
                            const formattedDatetime = `${dateParts[0]}/${dateParts[1]}/${dateParts[2]} ${timeParts[0]}:${timeParts[1]}:${timeParts[2]}`;

                            local_date_show = formattedDatetime
                          }
                          return (
                            <>
                              <tr>
                                <td>{row.local_date}</td>
                                <td>{row.utc_date}</td>
                              </tr>
                            </>
                          )
                        })
                      }
                    </>
                    :
                    <></>
                  }
                  {rows.length >= 24 ?
                    <tr>
                      <td className="dummyRow"></td>
                      <td className="dummyRow"></td>
                    </tr>
                    :
                    ""
                  }
                </tbody>
                :
                <Box
                  sx={{ height: 425 }}>
                  {loading && (
                    <CircularProgress
                      size={68}
                      sx={{
                        color: "grey",
                        position: 'absolute',
                        top: 500,
                        left: 1050,
                        zIndex: 10,
                      }}
                    />
                  )}
                </Box>
              }
            </table>
          </div>
          <div id="DataViewer-bottomRightArea" ref={bottomRightAreaRef}>
            <table id="DataViewer-bottomRightTable" className="fixedTable">
              {
                success ?
                  <tbody>

                    {rows[0]?.utc_date !== "" ?
                      <>
                        {rows.map((row, index) => {
                          return (
                            <>
                              <tr>
                                <td>{row.state}</td>
                                <td>{row.port_name}</td>
                                <td className={`${row.lat_alerm === 0 ? "" : "alerm"}`}>{row.lat}</td>
                                <td className={`${row.lng_alerm === 0 ? "" : "alerm"}`}>{row.lng}</td>
                                <td>{row.voyage_no}</td>
                                <td>{row.leg_no}</td>
                                <td>{row.leg}</td>
                                <td>{row.co2_factor}</td>
                                <td className={`${row.course_alerm === 0 ? "" : "alerm"}`}>{row.course}</td>
                                <td className={`${row.beaufort_alerm === 0 ? "" : "alerm"}`}>{row.beaufort}</td>
                                <td className={`${row.log_distance_alerm === 0 ? "" : "alerm"}`}>{row.log_distance}</td>
                                <td className={`${row.og_distance_alerm === 0 ? "" : "alerm"}`}>{row.og_distance}</td>
                                <td className={`${row.log_speed_alerm === 0 ? "" : "alerm"}`}>{row.log_speed}</td>
                                <td className={`${row.og_speed_alerm === 0 ? "" : "alerm"}`}>{row.og_speed}</td>
                                <td className={`${row.me_load_alerm === 0 ? "" : "alerm"}`}>{row.me_load}</td>
                                <td className={`${row.me_rpm_alerm === 0 ? "" : "alerm"}`}>{row.me_rpm}</td>
                                <td className={`${row.me_foc_alerm === 0 ? "" : "alerm"}`}>{row.me_foc}</td>
                                <td className={`${row.ge_foc_alerm === 0 ? "" : "alerm"}`}>{row.ge_foc}</td>
                                <td className={`${row.boiler_foc_alerm === 0 ? "" : "alerm"}`}>{row.boiler_foc}</td>
                                <td className={`${row.total_foc_alerm === 0 ? "" : "alerm"}`}>{row.total_foc}</td>
                                <td>{row.eta_local_date}</td>
                                <td>{row.eta_utc_date}</td>
                                <td>{row.eta_destination}</td>
                                <td className={`${row.displacement_alerm === 0 ? "" : "alerm"}`}>{row.displacement}</td>
                                <td className={`${row.gt_alerm === 0 ? "" : "alerm"}`}>{row.gt}</td>
                                <td className={`${row.dwt_alerm === 0 ? "" : "alerm"}`}>{row.dwt}</td>
                                <td className={`${row.wind_speed_alerm === 0 ? "" : "alerm"}`}>{row.wind_speed}</td>
                                <td className={`${row.wind_direction_alerm === 0 ? "" : "alerm"}`}>{row.wind_direction}</td>
                                <td className={`${row.wave_height_alerm === 0 ? "" : "alerm"}`}>{row.wave_height}</td>
                                <td className={`${row.wave_period_alerm === 0 ? "" : "alerm"}`}>{row.wave_period}</td>
                                <td className={`${row.wave_direction_alerm === 0 ? "" : "alerm"}`}>{row.wave_direction}</td>
                                <td className={`${row.swell_height_alerm === 0 ? "" : "alerm"}`}>{row.swell_height}</td>
                                <td className={`${row.swell_period_alerm === 0 ? "" : "alerm"}`}>{row.swell_period}</td>
                                <td className={`${row.swell_direction_alerm === 0 ? "" : "alerm"}`}>{row.swell_direction}</td>
                                <td>{row.ablog_id}</td>
                              </tr>
                            </>
                          )
                        })}
                      </>
                      :
                      <></>
                    }
                  </tbody>
                  :
                  <Box
                    sx={{ height: 425 }}>
                    {loading && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: 500,
                          left: 1050,
                          zIndex: 10,
                        }}
                      />
                    )}
                  </Box>
              }
            </table >
          </div>
          {/* Body */}

        </div>
        {/* Table */}

      </Main >
    </Box >
  );
} 