
import * as React from 'react';
import {
  Link,
  matchPath,
  useLocation,
} from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

const TabTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.50)',
          "&.Mui-selected": {
            color: "white",
          },
          "&:hover": {
            color: "white",
          },
        }
      }
    }
  }
})

function MyTabs() {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch(['/', '/VesselList/', '/VesselOverview/', '/EmissionBoard/', '/SpeedConsumption/', '/DataViewer/']);
  const [currentTab, setCurrentTab] = React.useState(routeMatch?.pattern?.path);
  // const currentTab = routeMatch?.pattern?.path;

  if (currentTab === "/" || currentTab === undefined) {
    setCurrentTab('/VesselList/');
  }

  // const tab_width = "80px"
  const tab_marginLeft = "4px"
  const tab_marginTop = "10px"
  const tab_blank = "24px"
  return (
    <ThemeProvider theme={TabTheme}>
      <Box sx={{
        position: "absolute",
        top: "60px",
        left: "0px",
        width: "100%",
        height: "4px",
        bgcolor: "rgba(255, 255, 255, 0.20)"
      }}>
      </Box>
      <Tabs
        aria-label="basic tabs example"
        sx={{ padding: '0', marginLeft: "20px", height: "64px", color: "white" }}
        TabIndicatorProps={{
          sx: {
            position: "absolute",
            top: "60px",
            margin: 0,
            padding: 0,
            marginBottom: "0px",
            backgroundColor: '#e66300',
            height: '10px',
            textTransform: 'none'
          }
        }}
        value={currentTab}
      >
        <Tab sx={{ minWidth: 0, padding: 0 }}
          label={
            <Stack direction={"column"} sx={{ width: "60px" }}>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft, marginTop: tab_marginTop }} >Vessel</Typography>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft }} >List</Typography>
            </Stack>}
          value="/VesselList/"
          to="/VesselList/"
          component={Link} />
        <Box sx={{ width: tab_blank, opacity: 0 }}>blank</Box>
        <Tab sx={{ minWidth: 0, padding: 0 }}
          label={
            <Stack direction={"column"} sx={{ width: "80px" }}>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft, marginTop: tab_marginTop }} >Emission</Typography>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft }} >Board</Typography>
            </Stack>}
          value="/EmissionBoard/"
          to="/EmissionBoard/"
          component={Link} />
        <Box sx={{ width: tab_blank, opacity: 0 }}>blank</Box>
        <Tab sx={{ minWidth: 0, padding: 0 }}
          label={
            <Stack direction={"column"} sx={{ width: "80px" }}>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft, marginTop: tab_marginTop }} >Vessel</Typography>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft }} >Overview</Typography>
            </Stack>}
          value="/VesselOverview/"
          to="/VesselOverview/"
          component={Link} />
        <Box sx={{ width: tab_blank, opacity: 0 }}>blank</Box>
        <Tab sx={{ minWidth: 0, padding: 0 }}
          label={
            <Stack direction={"column"} sx={{ width: "110px" }}>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft, marginTop: tab_marginTop }} >Speed </Typography>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft }} >Consumption</Typography>
            </Stack>}
          value="/SpeedConsumption/"
          to="/SpeedConsumption/"
          component={Link} />
        <Box sx={{ width: tab_blank, opacity: 0 }}>blank</Box>
        <Tab sx={{ minWidth: 0, padding: 0 }}
          label={
            <Stack direction={"column"} sx={{ width: "60px" }}>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft, marginTop: tab_marginTop }} >Data</Typography>
              <Typography align="left" sx={{ height: "20px", textTransform: 'none', marginLeft: tab_marginLeft }} >Viewer</Typography>
            </Stack>}
          value="/DataViewer/"
          to="/DataViewer/"
          component={Link} />
        <Box sx={{ width: tab_blank, opacity: 0 }}>blank</Box>
      </Tabs>
    </ThemeProvider>
  );
}

export const TabComponent = (props) => {
  return (
    <>
      <MyTabs />
    </>
  );
}