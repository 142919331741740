
import * as React from 'react';

import { TabComponent } from '../../components/components/TabComponent';
import { SettingComponent } from '../../components/components/SettingComponent';

// @mui/material/
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    // background: 'linear-gradient(360deg, #0D1532 0%, #2A3045 100%)',
    background: 'linear-gradient(360deg, #17232D 0%, #0E1B25 100%)',
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: 60,
}));


console.log("process.env.NODE_ENV", process.env.NODE_ENV)
console.log("process.env", process.env)

// WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
};


export default function Error(props) {

    // 初期表示時に、WADATSUMI認証で、ユーザIDを取得。ユーザIDを元に、SPM側で保持しているグループマスタを元にどこのグループ所属か抽出し、GroupStateにセットする。

    return (
        <Box height="100vh" sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* 認証エラーダイアログ */}
            {
                !props.loading ?
                    ""
                    :
                    <Dialog
                        open={!props.authCheckRusult}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "600px",
                                    height: "100%",
                                    maxHeight: "240px",
                                },
                            },
                        }}
                    >
                        <DialogTitle
                            sx={{
                                bgcolor: "#474C53",
                                height: "50px",
                                color: "white",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            UserId Update
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                            }}>
                            <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
                                認証エラーです。ログインしなおしてください。
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ height: "50px", bgcolor: "#474C53" }}>
                            <Button
                                sx={{
                                    marginLeft: "0px",
                                    marginRight: "-8px",
                                    backgroundColor: "#e66300",
                                    width: "127px",
                                    height: "50px",
                                    padding: "0",
                                    fontSize: "18px",
                                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                                    fontWeight: "bold",
                                    color: "white",
                                    borderRadius: "0px",
                                    ":hover": {
                                        bgcolor: "#FFFFFF",
                                        color: "#40475D",
                                    },
                                    textTransform: 'none'
                                }}
                                onClick={handleClickWADATSUMI}
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
            }

            {/* menu bar */}
            <MuiAppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    background: 'rgba(71, 76, 83, 1)'
                }}>
                <Toolbar>
                    <Typography sx={{ color: 'white', fontSize: 19, fontFamily: 'Gotham Black', fontWeight: 'bold', wordWrap: 'break-word' }}>
                        ECO Metrics
                    </Typography>
                    <Box sx={{ marginLeft: "40px", marginRight: "20px", height: "25px", width: "2px", background: '#f1f1f1', borderRadius: '2px ', border: '1px #f1f1f1 solid' }}></Box>
                    <TabComponent />
                    <SettingComponent company_id={"company_id"} group_id={"group_id"} user_id={"user_id"} role={"role"} />
                </Toolbar>
            </MuiAppBar>


            {/* メイン部分 */}
            <Main>
            </Main >
        </Box >
    );
}
