
import * as React from 'react';

import { TabComponent } from '../../components/components/TabComponent';
import { SettingComponent } from '../../components/components/SettingComponent';

// @mui/material/
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


function MyAppBar(props) {

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: 'rgba(71, 76, 83, 1)'
      }}>
      <Toolbar>
        <Typography sx={{ color: 'white', fontSize: 19, fontFamily: 'Gotham Black', fontWeight: 'bold', wordWrap: 'break-word' }}>
          ECO Metrics
        </Typography>
        <Box sx={{ marginLeft: "40px", marginRight: "20px", height: "25px", width: "2px", background: '#f1f1f1', borderRadius: '2px ', border: '1px #f1f1f1 solid' }}></Box>
        <TabComponent />
        <SettingComponent company_id={props.company_id} group_id={props.group_id} user_id={props.user_id} role={props.role} />
      </Toolbar>
    </MuiAppBar>
  );
}

export const AppBar = (props) => {
  return (
    <>
      <MyAppBar />
    </>
  );
}