
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './MasterInput.css'; // スタイルのために追加
import { SelectTheme } from '../../components/theme/SelectTheme';
import { Main, BootstrapInput } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { AppBar } from '../../components/components/AppBar';

// MUI @mui/material/
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

// MUI @mui/icons-material/
import StarIcon from '@mui/icons-material/Star';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

interface payload {
  role: string | null
  user_id: string | null
  group_id: string | null
  company_id: string | null
  gid: string | null
  gidList: Array<string>
  imoList: Array<string>
  jwt: string | null
}

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}



export default function MasterInput({ setSeachInfo }) {

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  React.useEffect(() => {
    if (init === true) {

      // localStrage取得----------------------------------------------------------------------------------------------------------------------------
      let jwt_ls
      let gid_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          jwt_ls = pay["jwt"]
          gid_ls = pay["user_id"]
          setUser_id(pay["user_id"])
          setGroup_id(pay["group_id"])
          setCompany_id(pay["company_id"])
          setGid(pay["gid"])
          setGidList(pay["gidList"])
          setImoList(pay["imoList"])
          setJwt(jwt_ls)
          setRole(pay["role"])
          setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: pay["gid"], gidList: pay["gidList"], imoList: pay["imoList"] })
        }
      }

      if (jwt_ls) {
        callAuth(jwt_ls).then(result => {
          if (result) {
            console.log("[MasterInput]認証成功");
            getVesselMaster(`${base_url}${SeachInfo.imoNo}`, jwt_ls)
            getVesselList(`${process.env.REACT_APP_API_URL}VesselList/%23/?user=${result["sub"]}&GID=${gid_ls}`, jwt_ls)
          } else {
            console.log("[MasterInput]認証失敗");
            setUserCheck(true)
            setMessage("認証エラー")
            localStorage.clear()
          }
        });
      }
    }

    init = false
  }, []);


  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
    // window.location.replace(WADATSUMIUrl)
  };

  // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);

  // 検索フォーム----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}MasterInput/%23/`);
  // const [base_url, setBase_url] = React.useState(`${BaseUrl}MasterInput/%23/`);


  // VesselMaser----------------------------------------------------------------------------------------------------------------------------
  const [VesselTypeList, setVesselTypeList] = React.useState<any>()
  const [OilTypeList, setOilTypeList] = React.useState<any>()
  const [Imo, setImo] = React.useState<string>("");
  const [ImoSelect, setImoSelect] = React.useState<boolean>(true);
  const selectImo = (event: SelectChangeEvent) => {
    setImo(event.target.value as string);
    setSeachInfo({ imoNo: `${event.target.value as string}`, from: SeachInfo.from, to: SeachInfo.to, gid: gid, gidList: gidList, imoList: imoList })
    setImoSelect(!ImoSelect)
  };
  React.useEffect(() => {
    setLoading(true);
    setSuccess(false);
    getVesselMaster(`${base_url}${SeachInfo.imoNo}`, jwt)
    getVesselList(`${process.env.REACT_APP_API_URL}VesselList/%23/?user=${user_id}&GID=${SeachInfo.gid}`, jwt)

  }, [ImoSelect])
  const [Ballast, setBallast] = React.useState("")
  const handleChanegeBallast = (value) => {
    if (/^\d*$/.test(value)) { setBallast(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [BuildDate, setBuildDate] = React.useState("")
  const handleChanegeBuildDate = (value) => { setBuildDate(value) };
  const [Deadweight, setDeadweight] = React.useState("")
  const handleChanegeDeadweight = (value) => {
    if (/^\d*$/.test(value)) { setDeadweight(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [Grosstongue, setGrosstongue] = React.useState("")
  const handleChanegeGrosstongue = (value) => {
    if (/^\d*$/.test(value)) { setGrosstongue(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [Laden, setLaden] = React.useState("")
  const handleChanegeLaden = (value) => {
    if (/^\d*$/.test(value)) { setLaden(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [LastDockDate, setLastDockDate] = React.useState("")
  const handleChanegeLastDockDate = (value) => { setLastDockDate(value) };
  const [HullCleaningDate, setHullCleaningDate] = React.useState("")
  const handleChanegeHullCleaningDate = (value) => { setHullCleaningDate(value) };
  const [OilType, setOilType] = React.useState("")
  const handleChanegeOilType = (event: SelectChangeEvent) => { setOilType(event.target.value as string) };
  const [Owner, setOwner] = React.useState("")
  const handleChanegeOwner = (value) => { setOwner(value) };
  const [Size, setSize] = React.useState("")
  const handleChanegeSize = (value) => { setSize(value) };
  const [VesselName, setVesselName] = React.useState("")
  const handleChanegeVesselName = (value) => { setVesselName(value) };
  const [VesselType, setVesselType] = React.useState("")
  const handleChanegeVesselType = (event: SelectChangeEvent) => { setVesselType(event.target.value as string) };
  // 一時保存用
  const [ImoTemp, setImoTemp] = React.useState("")
  const [BallastTemp, setBallastTemp] = React.useState("")
  const [BuildDateTemp, setBuildDateTemp] = React.useState("")
  const [DeadweightTemp, setDeadweightTemp] = React.useState("")
  const [GrosstongueTemp, setGrosstongueTemp] = React.useState("")
  const [LadenTemp, setLadenTemp] = React.useState("")
  const [LastDockDateTemp, setLastDockDateTemp] = React.useState("")
  const [HullCleaningDateTemp, setHullCleaningDateTemp] = React.useState("")
  const [OilTypeTemp, setOilTypeTemp] = React.useState("")
  const [OwnerTemp, setOwnerTemp] = React.useState("")
  const [SizeTemp, setSizeTemp] = React.useState("")
  const [VesselNameTemp, setVesselNameTemp] = React.useState("")
  const [VesselTypeTemp, setVesselTypeTemp] = React.useState("")

  // Data Channel----------------------------------------------------------------------------------------------------------------------------
  const [imo, setimo] = React.useState("")
  const [rev, setrev] = React.useState("")
  const [lower_beaufort, setlower_beaufort] = React.useState("")
  const handleChanegelower_beaufort = (value) => {
    // 正規表現で入力チェックを行う
    if (/^-?\d*$/.test(value)) { setlower_beaufort(value); }
    else { alert("半角数字のみ、またはマイナスの数値のみを入力してください。"); }
  };
  const [lower_boiler_foc, setlower_boiler_foc] = React.useState("")
  const handleChanegelower_boiler_foc = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_boiler_foc(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_course, setlower_course] = React.useState("")
  const handleChanegelower_course = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_course(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_displacement, setlower_displacement] = React.useState("")
  const handleChanegelower_displacement = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_displacement(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_dwt, setlower_dwt] = React.useState("")
  const handleChanegelower_dwt = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_dwt(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_ge_foc, setlower_ge_foc] = React.useState("")
  const handleChanegelower_ge_foc = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_ge_foc(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_gt, setlower_gt] = React.useState("")
  const handleChanegelower_gt = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_gt(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_lat, setlower_lat] = React.useState("")
  const handleChanegelower_lat = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_lat(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_lng, setlower_lng] = React.useState("")
  const handleChanegelower_lng = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_lng(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_log_distance, setlower_log_distance] = React.useState("")
  const handleChanegelower_log_distance = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_log_distance(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_log_speed, setlower_log_speed] = React.useState("")
  const handleChanegelower_log_speed = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_log_speed(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_me_foc, setlower_me_foc] = React.useState("")
  const handleChanegelower_me_foc = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_me_foc(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_me_load, setlower_me_load] = React.useState("")
  const handleChanegelower_me_load = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_me_load(value) }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_me_rpm, setlower_me_rpm] = React.useState("")
  const handleChanegelower_me_rpm = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_me_rpm(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_og_distance, setlower_og_distance] = React.useState("")
  const handleChanegelower_og_distance = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_og_distance(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_og_speed, setlower_og_speed] = React.useState("")
  const handleChanegelower_og_speed = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_og_speed(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_swell_direction, setlower_swell_direction] = React.useState("")
  const handleChanegelower_swell_direction = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_swell_direction(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_swell_height, setlower_swell_height] = React.useState("")
  const handleChanegelower_swell_height = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_swell_height(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_swell_period, setlower_swell_period] = React.useState("")
  const handleChanegelower_swell_period = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_swell_period(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_total_foc, setlower_total_foc] = React.useState("")
  const handleChanegelower_total_foc = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_total_foc(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_wave_direction, setlower_wave_direction] = React.useState("")
  const handleChanegelower_wave_direction = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_wave_direction(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_wave_height, setlower_wave_height] = React.useState("")
  const handleChanegelower_wave_height = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_wave_height(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_wave_period, setlower_wave_period] = React.useState("")
  const handleChanegelower_wave_period = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_wave_period(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_wind_direction, setlower_wind_direction] = React.useState("")
  const handleChanegelower_wind_direction = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_wind_direction(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [lower_wind_speed, setlower_wind_speed] = React.useState("")
  const handleChanegelower_wind_speed = (value) => {
    if (/^-?\d*$/.test(value)) { setlower_wind_speed(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_beaufort, setupper_beaufort] = React.useState("")
  const handleChanegeupper_beaufort = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_beaufort(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_boiler_foc, setupper_boiler_foc] = React.useState("")
  const handleChanegeupper_boiler_foc = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_boiler_foc(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_course, setupper_course] = React.useState("")
  const handleChanegeupper_course = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_course(value) }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_displacement, setupper_displacement] = React.useState("")
  const handleChanegeupper_displacement = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_displacement(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_dwt, setupper_dwt] = React.useState("")
  const handleChanegeupper_dwt = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_dwt(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_ge_foc, setupper_ge_foc] = React.useState("")
  const handleChanegeupper_ge_foc = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_ge_foc(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_gt, setupper_gt] = React.useState("")
  const handleChanegeupper_gt = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_gt(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_lat, setupper_lat] = React.useState("")
  const handleChanegeupper_lat = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_lat(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_lng, setupper_lng] = React.useState("")
  const handleChanegeupper_lng = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_lng(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_log_distance, setupper_log_distance] = React.useState("")
  const handleChanegeupper_log_distance = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_log_distance(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_log_speed, setupper_log_speed] = React.useState("")
  const handleChanegeupper_log_speed = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_log_speed(value) }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_me_foc, setupper_me_foc] = React.useState("")
  const handleChanegeupper_me_foc = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_me_foc(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_me_load, setupper_me_load] = React.useState("")
  const handleChanegeupper_me_load = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_me_load(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_me_rpm, setupper_me_rpm] = React.useState("")
  const handleChanegeupper_me_rpm = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_me_rpm(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_og_distance, setupper_og_distance] = React.useState("")
  const handleChanegeupper_og_distance = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_og_distance(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_og_speed, setupper_og_speed] = React.useState("")
  const handleChanegeupper_og_speed = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_og_speed(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_swell_direction, setupper_swell_direction] = React.useState("")
  const handleChanegeupper_swell_direction = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_swell_direction(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_swell_height, setupper_swell_height] = React.useState("")
  const handleChanegeupper_swell_height = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_swell_height(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_swell_period, setupper_swell_period] = React.useState("")
  const handleChanegeupper_swell_period = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_swell_period(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_total_foc, setupper_total_foc] = React.useState("")
  const handleChanegeupper_total_foc = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_total_foc(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_wave_direction, setupper_wave_direction] = React.useState("")
  const handleChanegeupper_wave_direction = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_wave_direction(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_wave_height, setupper_wave_height] = React.useState("")
  const handleChanegeupper_wave_height = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_wave_height(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_wave_period, setupper_wave_period] = React.useState("")
  const handleChanegeupper_wave_period = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_wave_period(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_wind_direction, setupper_wind_direction] = React.useState("")
  const handleChanegeupper_wind_direction = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_wind_direction(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [upper_wind_speed, setupper_wind_speed] = React.useState("")
  const handleChanegeupper_wind_speed = (value) => {
    if (/^-?\d*$/.test(value)) { setupper_wind_speed(value); }
    else { alert("半角数字で入力してください。") }
  };
  // 一次保存用
  const [imoTemp, setimoTemp] = React.useState("")
  const [revTemp, setrevTemp] = React.useState("")
  const [lower_beaufortTemp, setlower_beaufortTemp] = React.useState("")
  const [lower_boiler_focTemp, setlower_boiler_focTemp] = React.useState("")
  const [lower_courseTemp, setlower_courseTemp] = React.useState("")
  const [lower_displacementTemp, setlower_displacementTemp] = React.useState("")
  const [lower_dwtTemp, setlower_dwtTemp] = React.useState("")
  const [lower_ge_focTemp, setlower_ge_focTemp] = React.useState("")
  const [lower_gtTemp, setlower_gtTemp] = React.useState("")
  const [lower_latTemp, setlower_latTemp] = React.useState("")
  const [lower_lngTemp, setlower_lngTemp] = React.useState("")
  const [lower_log_distanceTemp, setlower_log_distanceTemp] = React.useState("")
  const [lower_log_speedTemp, setlower_log_speedTemp] = React.useState("")
  const [lower_me_focTemp, setlower_me_focTemp] = React.useState("")
  const [lower_me_loadTemp, setlower_me_loadTemp] = React.useState("")
  const [lower_me_rpmTemp, setlower_me_rpmTemp] = React.useState("")
  const [lower_og_distanceTemp, setlower_og_distanceTemp] = React.useState("")
  const [lower_og_speedTemp, setlower_og_speedTemp] = React.useState("")
  const [lower_swell_directionTemp, setlower_swell_directionTemp] = React.useState("")
  const [lower_swell_heightTemp, setlower_swell_heightTemp] = React.useState("")
  const [lower_swell_periodTemp, setlower_swell_periodTemp] = React.useState("")
  const [lower_total_focTemp, setlower_total_focTemp] = React.useState("")
  const [lower_wave_directionTemp, setlower_wave_directionTemp] = React.useState("")
  const [lower_wave_heightTemp, setlower_wave_heightTemp] = React.useState("")
  const [lower_wave_periodTemp, setlower_wave_periodTemp] = React.useState("")
  const [lower_wind_directionTemp, setlower_wind_directionTemp] = React.useState("")
  const [lower_wind_speedTemp, setlower_wind_speedTemp] = React.useState("")
  const [upper_beaufortTemp, setupper_beaufortTemp] = React.useState("")
  const [upper_boiler_focTemp, setupper_boiler_focTemp] = React.useState("")
  const [upper_courseTemp, setupper_courseTemp] = React.useState("")
  const [upper_displacementTemp, setupper_displacementTemp] = React.useState("")
  const [upper_dwtTemp, setupper_dwtTemp] = React.useState("")
  const [upper_ge_focTemp, setupper_ge_focTemp] = React.useState("")
  const [upper_gtTemp, setupper_gtTemp] = React.useState("")
  const [upper_latTemp, setupper_latTemp] = React.useState("")
  const [upper_lngTemp, setupper_lngTemp] = React.useState("")
  const [upper_log_distanceTemp, setupper_log_distanceTemp] = React.useState("")
  const [upper_log_speedTemp, setupper_log_speedTemp] = React.useState("")
  const [upper_me_focTemp, setupper_me_focTemp] = React.useState("")
  const [upper_me_loadTemp, setupper_me_loadTemp] = React.useState("")
  const [upper_me_rpmTemp, setupper_me_rpmTemp] = React.useState("")
  const [upper_og_distanceTemp, setupper_og_distanceTemp] = React.useState("")
  const [upper_og_speedTemp, setupper_og_speedTemp] = React.useState("")
  const [upper_swell_directionTemp, setupper_swell_directionTemp] = React.useState("")
  const [upper_swell_heightTemp, setupper_swell_heightTemp] = React.useState("")
  const [upper_swell_periodTemp, setupper_swell_periodTemp] = React.useState("")
  const [upper_total_focTemp, setupper_total_focTemp] = React.useState("")
  const [upper_wave_directionTemp, setupper_wave_directionTemp] = React.useState("")
  const [upper_wave_heightTemp, setupper_wave_heightTemp] = React.useState("")
  const [upper_wave_periodTemp, setupper_wave_periodTemp] = React.useState("")
  const [upper_wind_directionTemp, setupper_wind_directionTemp] = React.useState("")
  const [upper_wind_speedTemp, setupper_wind_speedTemp] = React.useState("")

  // Speed Consumption----------------------------------------------------------------------------------------------------------------------------
  const [CPCurveName, setCPCurveName] = React.useState("")
  const [CPCurveA, setCPCurveA] = React.useState("")
  const handleChanegeCPCurveA = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setCPCurveA(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [CPCurveAlpha, setCPCurveAlpha] = React.useState("")
  const handleChanegeCPCurveAlpha = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setCPCurveAlpha(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [CPCurveC, setCPCurveC] = React.useState("")
  const handleChanegeCPCurveC = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setCPCurveC(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [CPCurveDisplay, setCPCurveDisplay] = React.useState("")

  const [ReferenceCurveName, setReferenceCurveName] = React.useState("")
  const [ReferenceCurveA, setReferenceCurveA] = React.useState("")
  const handleChanegeReferenceCurveA = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setReferenceCurveA(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [ReferenceCurveAlpha, setReferenceCurveAlpha] = React.useState("")
  const handleChanegeReferenceCurveAlpha = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setReferenceCurveAlpha(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [ReferenceCurveC, setReferenceCurveC] = React.useState("")
  const handleChanegeReferenceCurveC = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setReferenceCurveC(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [ReferenceCurveDisplay, setReferenceCurveDisplay] = React.useState("")

  const [OtherCurve1Name, setOtherCurve1Name] = React.useState("")
  const handleChanegeOtherCurve1Name = (value) => { setOtherCurve1Name(value) };
  const [OtherCurve1A, setOtherCurve1A] = React.useState("")
  const handleChanegeOtherCurve1A = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve1A(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve1Alpha, setOtherCurve1Alpha] = React.useState("")
  const handleChanegeOtherCurve1Alpha = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve1Alpha(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve1C, setOtherCurve1C] = React.useState("")
  const handleChanegeOtherCurve1C = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve1C(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve1Display, setOtherCurve1Display] = React.useState("")
  const handleChanegeOtherCurve1Display = (event: SelectChangeEvent) => {
    setOtherCurve1Display(event.target.value as string);
  };


  const [OtherCurve2Name, setOtherCurve2Name] = React.useState("")
  const handleChanegeOtherCurve2Name = (value) => { setOtherCurve2Name(value) };
  const [OtherCurve2A, setOtherCurve2A] = React.useState("")
  const handleChanegeOtherCurve2A = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve2A(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve2Alpha, setOtherCurve2Alpha] = React.useState("")
  const handleChanegeOtherCurve2Alpha = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve2Alpha(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve2C, setOtherCurve2C] = React.useState("")
  const handleChanegeOtherCurve2C = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve2C(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve2Display, setOtherCurve2Display] = React.useState("")
  const handleChanegeOtherCurve2Display = (event: SelectChangeEvent) => {
    setOtherCurve2Display(event.target.value as string);
  };

  const [OtherCurve3Name, setOtherCurve3Name] = React.useState("")
  const handleChanegeOtherCurve3Name = (value) => { setOtherCurve3Name(value) };
  const [OtherCurve3A, setOtherCurve3A] = React.useState("")
  const handleChanegeOtherCurve3A = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve3A(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve3Alpha, setOtherCurve3Alpha] = React.useState("")
  const handleChanegeOtherCurve3Alpha = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve3Alpha(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve3C, setOtherCurve3C] = React.useState("")
  const handleChanegeOtherCurve3C = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve3C(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve3Display, setOtherCurve3Display] = React.useState("")
  const handleChanegeOtherCurve3Display = (event: SelectChangeEvent) => {
    setOtherCurve3Display(event.target.value as string);
  };

  const [OtherCurve4Name, setOtherCurve4Name] = React.useState("")
  const handleChanegeOtherCurve4Name = (value) => { setOtherCurve4Name(value) };
  const [OtherCurve4A, setOtherCurve4A] = React.useState("")
  const handleChanegeOtherCurve4A = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve4A(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve4Alpha, setOtherCurve4Alpha] = React.useState("")
  const handleChanegeOtherCurve4Alpha = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve4Alpha(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve4C, setOtherCurve4C] = React.useState("")
  const handleChanegeOtherCurve4C = (value) => {
    if (/^-?\d*\.?\d*$/.test(value)) { setOtherCurve4C(value); }
    else { alert("半角数字で入力してください。") }
  };
  const [OtherCurve4Display, setOtherCurve4Display] = React.useState("")
  const handleChanegeOtherCurve4Display = (event: SelectChangeEvent) => {
    setOtherCurve4Display(event.target.value as string);
  };

  // 一次保存用
  const [CPCurveNameTemp, setCPCurveNameTemp] = React.useState("")
  const [CPCurveATemp, setCPCurveATemp] = React.useState("")
  const [CPCurveAlphaTemp, setCPCurveAlphaTemp] = React.useState("")
  const [CPCurveCTemp, setCPCurveCTemp] = React.useState("")
  const [CPCurveDisplayTemp, setCPCurveDisplayTemp] = React.useState("")

  const [ReferenceCurveNameTemp, setReferenceCurveNameTemp] = React.useState("")
  const [ReferenceCurveATemp, setReferenceCurveATemp] = React.useState("")
  const [ReferenceCurveAlphaTemp, setReferenceCurveAlphaTemp] = React.useState("")
  const [ReferenceCurveCTemp, setReferenceCurveCTemp] = React.useState("")
  const [ReferenceCurveDisplayTemp, setReferenceCurveDisplayTemp] = React.useState("")

  const [OtherCurve1NameTemp, setOtherCurve1NameTemp] = React.useState("")
  const [OtherCurve1ATemp, setOtherCurve1ATemp] = React.useState("")
  const [OtherCurve1AlphaTemp, setOtherCurve1AlphaTemp] = React.useState("")
  const [OtherCurve1CTemp, setOtherCurve1CTemp] = React.useState("")
  const [OtherCurve1DisplayTemp, setOtherCurve1DisplayTemp] = React.useState("")


  const [OtherCurve2NameTemp, setOtherCurve2NameTemp] = React.useState("")
  const [OtherCurve2ATemp, setOtherCurve2ATemp] = React.useState("")
  const [OtherCurve2AlphaTemp, setOtherCurve2AlphaTemp] = React.useState("")
  const [OtherCurve2CTemp, setOtherCurve2CTemp] = React.useState("")
  const [OtherCurve2DisplayTemp, setOtherCurve2DisplayTemp] = React.useState("")

  const [OtherCurve3NameTemp, setOtherCurve3NameTemp] = React.useState("")
  const [OtherCurve3ATemp, setOtherCurve3ATemp] = React.useState("")
  const [OtherCurve3AlphaTemp, setOtherCurve3AlphaTemp] = React.useState("")
  const [OtherCurve3CTemp, setOtherCurve3CTemp] = React.useState("")
  const [OtherCurve3DisplayTemp, setOtherCurve3DisplayTemp] = React.useState("")

  const [OtherCurve4NameTemp, setOtherCurve4NameTemp] = React.useState("")
  const [OtherCurve4ATemp, setOtherCurve4ATemp] = React.useState("")
  const [OtherCurve4AlphaTemp, setOtherCurve4AlphaTemp] = React.useState("")
  const [OtherCurve4CTemp, setOtherCurve4CTemp] = React.useState("")
  const [OtherCurve4DisplayTemp, setOtherCurve4DisplayTemp] = React.useState("")


  // Save Dialog----------------------------------------------------------------------------------------------------------------------------
  const [openDialogSave, setOpenDialogSave] = React.useState(false);
  const handleClickOpenDialogSave = () => {
    setOpenDialogSave(true);
  };
  const handleNoClickOpenDialogSave = () => {
    setOpenDialogSave(false);
  };
  const handleClickInsert = () => {
    const value = {
      // VesselMaster
      "Imo": Imo,
      "Ballast": Ballast,
      "BuildDate": BuildDate,
      "Deadweight": Deadweight,
      "Grosstongue": Grosstongue,
      "Laden": Laden,
      "LastDockDate": LastDockDate,
      "HullCleaningDate": HullCleaningDate,
      "OilType": OilType,
      "Owner": Owner,
      "Size": Size,
      "VesselName": VesselName,
      "VesselType": VesselType,

      // DataChannel
      "imo": imo,
      "rev": rev,
      "lower_beaufort": lower_beaufort,
      "lower_boiler_foc": lower_boiler_foc,
      "lower_course": lower_course,
      "lower_displacement": lower_displacement,
      "lower_dwt": lower_dwt,
      "lower_ge_foc": lower_ge_foc,
      "lower_gt": lower_gt,
      "lower_lat": lower_lat,
      "lower_lng": lower_lng,
      "lower_log_distance": lower_log_distance,
      "lower_log_speed": lower_log_speed,
      "lower_me_foc": lower_me_foc,
      "lower_me_load": lower_me_load,
      "lower_me_rpm": lower_me_rpm,
      "lower_og_distance": lower_og_distance,
      "lower_og_speed": lower_og_speed,
      "lower_swell_direction": lower_swell_direction,
      "lower_swell_height": lower_swell_height,
      "lower_swell_period": lower_swell_period,
      "lower_total_foc": lower_total_foc,
      "lower_wave_direction": lower_wave_direction,
      "lower_wave_height": lower_wave_height,
      "lower_wave_period": lower_wave_period,
      "lower_wind_direction": lower_wind_direction,
      "lower_wind_speed": lower_wind_speed,
      "upper_beaufort": upper_beaufort,
      "upper_boiler_foc": upper_boiler_foc,
      "upper_course": upper_course,
      "upper_displacement": upper_displacement,
      "upper_dwt": upper_dwt,
      "upper_ge_foc": upper_ge_foc,
      "upper_gt": upper_gt,
      "upper_lat": upper_lat,
      "upper_lng": upper_lng,
      "upper_log_distance": upper_log_distance,
      "upper_log_speed": upper_log_speed,
      "upper_me_foc": upper_me_foc,
      "upper_me_load": upper_me_load,
      "upper_me_rpm": upper_me_rpm,
      "upper_og_distance": upper_og_distance,
      "upper_og_speed": upper_og_speed,
      "upper_swell_direction": upper_swell_direction,
      "upper_swell_height": upper_swell_height,
      "upper_swell_period": upper_swell_period,
      "upper_total_foc": upper_total_foc,
      "upper_wave_direction": upper_wave_direction,
      "upper_wave_height": upper_wave_height,
      "upper_wave_period": upper_wave_period,
      "upper_wind_direction": upper_wind_direction,
      "upper_wind_speed": upper_wind_speed,


      // Speed Consumption----------------------------------------------------------------------------------------------------------------------------
      "cpcurve_name": CPCurveName,
      "cpcurve_a": CPCurveA,
      "cpcurve_alpha": CPCurveAlpha,
      "cpcurve_c": CPCurveC,
      "cpcurve_display": CPCurveDisplay,
      "rfcurve_name": ReferenceCurveName,
      "rfcurve_a": ReferenceCurveA,
      "rfcurve_alpha": ReferenceCurveAlpha,
      "rfcurve_c": ReferenceCurveC,
      "rfcurve_display": ReferenceCurveDisplay,
      "spcurve1_name": OtherCurve1Name,
      "spcurve1_a": OtherCurve1A,
      "spcurve1_alpha": OtherCurve1Alpha,
      "spcurve1_c": OtherCurve1C,
      "spcurve1_display": OtherCurve1Display,
      "spcurve2_name": OtherCurve2Name,
      "spcurve2_a": OtherCurve2A,
      "spcurve2_alpha": OtherCurve2Alpha,
      "spcurve2_c": OtherCurve2C,
      "spcurve2_display": OtherCurve2Display,
      "spcurve3_name": OtherCurve3Name,
      "spcurve3_a": OtherCurve3A,
      "spcurve3_alpha": OtherCurve3Alpha,
      "spcurve3_c": OtherCurve3C,
      "spcurve3_display": OtherCurve3Display,
      "spcurve4_name": OtherCurve4Name,
      "spcurve4_a": OtherCurve4A,
      "spcurve4_alpha": OtherCurve4Alpha,
      "spcurve4_c": OtherCurve4C,
      "spcurve4_display": OtherCurve4Display,
    }
    insertVesselMaster(value)
    if (user_id !== undefined) {
      getVesselList(`${process.env.REACT_APP_API_URL}VesselList/%23/?user=${user_id}&GID=${SeachInfo.gid}`, jwt)
    }
    setOpenDialogSave(false);
  }

  // Cancel Dialog----------------------------------------------------------------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");
  const [openDialogCancel, setOpenDialogCancel] = React.useState(false);
  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };
  const handleNoClickOpenDialogCancel = () => {
    setOpenDialogCancel(false);
  };
  const handleClickReset = () => {
    // VesselMaster
    setImo(ImoTemp)
    setBallast(BallastTemp)
    setBuildDate(BuildDateTemp)
    setDeadweight(DeadweightTemp)
    setGrosstongue(GrosstongueTemp)
    setLaden(LadenTemp)
    setLastDockDate(LastDockDateTemp)
    setHullCleaningDate(HullCleaningDateTemp)
    setOilType(OilTypeTemp)
    setOwner(OwnerTemp)
    setSize(SizeTemp)
    setVesselName(VesselNameTemp)
    setVesselType(VesselTypeTemp)

    // DataChannel
    setimo(imoTemp)
    setrev(revTemp)
    setlower_beaufort(lower_beaufortTemp)
    setlower_boiler_foc(lower_boiler_focTemp)
    setlower_course(lower_courseTemp)
    setlower_displacement(lower_displacementTemp)
    setlower_dwt(lower_dwtTemp)
    setlower_ge_foc(lower_ge_focTemp)
    setlower_gt(lower_gtTemp)
    setlower_lat(lower_latTemp)
    setlower_lng(lower_lngTemp)
    setlower_log_distance(lower_log_distanceTemp)
    setlower_log_speed(lower_log_speedTemp)
    setlower_me_foc(lower_me_focTemp)
    setlower_me_load(lower_me_loadTemp)
    setlower_me_rpm(lower_me_rpmTemp)
    setlower_og_distance(lower_og_distanceTemp)
    setlower_og_speed(lower_og_speedTemp)
    setlower_swell_direction(lower_swell_directionTemp)
    setlower_swell_height(lower_swell_heightTemp)
    setlower_swell_period(lower_swell_periodTemp)
    setlower_total_foc(lower_total_focTemp)
    setlower_wave_direction(lower_wave_directionTemp)
    setlower_wave_height(lower_wave_heightTemp)
    setlower_wave_period(lower_wave_periodTemp)
    setlower_wind_direction(lower_wind_directionTemp)
    setlower_wind_speed(lower_wind_speedTemp)
    setupper_beaufort(upper_beaufortTemp)
    setupper_boiler_foc(upper_boiler_focTemp)
    setupper_course(upper_courseTemp)
    setupper_displacement(upper_displacementTemp)
    setupper_dwt(upper_dwtTemp)
    setupper_ge_foc(upper_ge_focTemp)
    setupper_gt(upper_gtTemp)
    setupper_lat(upper_latTemp)
    setupper_lng(upper_lngTemp)
    setupper_log_distance(upper_log_distanceTemp)
    setupper_log_speed(upper_log_speedTemp)
    setupper_me_foc(upper_me_focTemp)
    setupper_me_load(upper_me_loadTemp)
    setupper_me_rpm(upper_me_rpmTemp)
    setupper_og_distance(upper_og_distanceTemp)
    setupper_og_speed(upper_og_speedTemp)
    setupper_swell_direction(upper_swell_directionTemp)
    setupper_swell_height(upper_swell_heightTemp)
    setupper_swell_period(upper_swell_periodTemp)
    setupper_total_foc(upper_total_focTemp)
    setupper_wave_direction(upper_wave_directionTemp)
    setupper_wave_height(upper_wave_heightTemp)
    setupper_wave_period(upper_wave_periodTemp)
    setupper_wind_direction(upper_wind_directionTemp)
    setupper_wind_speed(upper_wind_speedTemp)

    // Speed Consumption----------------------------------------------------------------------------------------------------------------------------
    setCPCurveName(CPCurveNameTemp)
    setCPCurveA(CPCurveATemp)
    setCPCurveAlpha(CPCurveAlphaTemp)
    setCPCurveC(CPCurveCTemp)
    setCPCurveDisplay(CPCurveDisplayTemp)
    setReferenceCurveName(ReferenceCurveNameTemp)
    setReferenceCurveA(ReferenceCurveATemp)
    setReferenceCurveAlpha(ReferenceCurveAlphaTemp)
    setReferenceCurveC(ReferenceCurveCTemp)
    setReferenceCurveDisplay(ReferenceCurveDisplayTemp)
    setOtherCurve1Name(OtherCurve1NameTemp)
    setOtherCurve1A(OtherCurve1ATemp)
    setOtherCurve1Alpha(OtherCurve1AlphaTemp)
    setOtherCurve1C(OtherCurve1CTemp)
    setOtherCurve1Display(OtherCurve1DisplayTemp)
    setOtherCurve2Name(OtherCurve2NameTemp)
    setOtherCurve2A(OtherCurve2ATemp)
    setOtherCurve2Alpha(OtherCurve2AlphaTemp)
    setOtherCurve2C(OtherCurve2CTemp)
    setOtherCurve2Display(OtherCurve2DisplayTemp)
    setOtherCurve3Name(OtherCurve3NameTemp)
    setOtherCurve3A(OtherCurve3ATemp)
    setOtherCurve3Alpha(OtherCurve3AlphaTemp)
    setOtherCurve3C(OtherCurve3CTemp)
    setOtherCurve3Display(OtherCurve3DisplayTemp)
    setOtherCurve4Name(OtherCurve4NameTemp)
    setOtherCurve4A(OtherCurve4ATemp)
    setOtherCurve4Alpha(OtherCurve4AlphaTemp)
    setOtherCurve4C(OtherCurve4CTemp)
    setOtherCurve4Display(OtherCurve4DisplayTemp)

    setOpenDialogCancel(false);
  }

  // VesselMaster登録--------------------------------------------------------------------
  const insertVesselMaster = (value) => {
    console.log("insertVesselMaster")

    if (SeachInfo.imoNo !== "" && jwt !== undefined) {
      console.log("insertVesselMasterスタート", value)
      const formData = new FormData();
      Object.keys(value).forEach(key => {
        formData.append(key, value[key]);
      });
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': jwt
        }
      }
      const url = `${base_url}`
      console.log("url[insertVesselMaster]:", url);
      axios.post(url, formData, options).then((res) => {
        console.log("API Response", res.data);

        // 認可チェック
        if (res.data === 401) {
          setAuthCheck(true)
          setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          setAuthCheck(true)
          setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          setAuthCheck(false)
        }

        // VesselMaster
        setImo(res.data.vesselmaster.Imo)
        setBallast(res.data.vesselmaster.Ballast)
        setBuildDate(res.data.vesselmaster.BuildDate)
        setDeadweight(res.data.vesselmaster.Deadweight)
        setGrosstongue(res.data.vesselmaster.Grosstongue)
        setLaden(res.data.vesselmaster.Laden)
        setLastDockDate(res.data.vesselmaster.LastDockDate)
        setHullCleaningDate(res.data.vesselmaster.HullCleaningDate)
        setOilType(res.data.vesselmaster.OilType)
        setOwner(res.data.vesselmaster.Owner)
        setSize(res.data.vesselmaster.Size)
        setVesselName(res.data.vesselmaster.VesselName)
        setVesselType(res.data.vesselmaster.VesselType)

        setImoTemp(res.data.vesselmaster.Imo)
        setBallastTemp(res.data.vesselmaster.Ballast)
        setBuildDateTemp(res.data.vesselmaster.BuildDate)
        setDeadweightTemp(res.data.vesselmaster.Deadweight)
        setGrosstongueTemp(res.data.vesselmaster.Grosstongue)
        setLadenTemp(res.data.vesselmaster.Laden)
        setLastDockDateTemp(res.data.vesselmaster.LastDockDate)
        setHullCleaningDateTemp(res.data.vesselmaster.HullCleaningDate)
        setOilTypeTemp(res.data.vesselmaster.OilType)
        setOwnerTemp(res.data.vesselmaster.Owner)
        setSizeTemp(res.data.vesselmaster.Size)
        setVesselNameTemp(res.data.vesselmaster.VesselName)
        setVesselTypeTemp(res.data.vesselmaster.VesselType)

        // DataChannel
        setimo(res.data.datachannel.imo)
        setrev(res.data.datachannel.rev)
        setlower_beaufort(res.data.datachannel.lower_beaufort)
        setlower_boiler_foc(res.data.datachannel.lower_boiler_foc)
        setlower_course(res.data.datachannel.lower_course)
        setlower_displacement(res.data.datachannel.lower_displacement)
        setlower_dwt(res.data.datachannel.lower_dwt)
        setlower_ge_foc(res.data.datachannel.lower_ge_foc)
        setlower_gt(res.data.datachannel.lower_gt)
        setlower_lat(res.data.datachannel.lower_lat)
        setlower_lng(res.data.datachannel.lower_lng)
        setlower_log_distance(res.data.datachannel.lower_log_distance)
        setlower_log_speed(res.data.datachannel.lower_log_speed)
        setlower_me_foc(res.data.datachannel.lower_me_foc)
        setlower_me_load(res.data.datachannel.lower_me_load)
        setlower_me_rpm(res.data.datachannel.lower_me_rpm)
        setlower_og_distance(res.data.datachannel.lower_og_distance)
        setlower_og_speed(res.data.datachannel.lower_og_speed)
        setlower_swell_direction(res.data.datachannel.lower_swell_direction)
        setlower_swell_height(res.data.datachannel.lower_swell_height)
        setlower_swell_period(res.data.datachannel.lower_swell_period)
        setlower_total_foc(res.data.datachannel.lower_total_foc)
        setlower_wave_direction(res.data.datachannel.lower_wave_direction)
        setlower_wave_height(res.data.datachannel.lower_wave_height)
        setlower_wave_period(res.data.datachannel.lower_wave_period)
        setlower_wind_direction(res.data.datachannel.lower_wind_direction)
        setlower_wind_speed(res.data.datachannel.lower_wind_speed)
        setupper_beaufort(res.data.datachannel.upper_beaufort)
        setupper_boiler_foc(res.data.datachannel.upper_boiler_foc)
        setupper_course(res.data.datachannel.upper_course)
        setupper_displacement(res.data.datachannel.upper_displacement)
        setupper_dwt(res.data.datachannel.upper_dwt)
        setupper_ge_foc(res.data.datachannel.upper_ge_foc)
        setupper_gt(res.data.datachannel.upper_gt)
        setupper_lat(res.data.datachannel.upper_lat)
        setupper_lng(res.data.datachannel.upper_lng)
        setupper_log_distance(res.data.datachannel.upper_log_distance)
        setupper_log_speed(res.data.datachannel.upper_log_speed)
        setupper_me_foc(res.data.datachannel.upper_me_foc)
        setupper_me_load(res.data.datachannel.upper_me_load)
        setupper_me_rpm(res.data.datachannel.upper_me_rpm)
        setupper_og_distance(res.data.datachannel.upper_og_distance)
        setupper_og_speed(res.data.datachannel.upper_og_speed)
        setupper_swell_direction(res.data.datachannel.upper_swell_direction)
        setupper_swell_height(res.data.datachannel.upper_swell_height)
        setupper_swell_period(res.data.datachannel.upper_swell_period)
        setupper_total_foc(res.data.datachannel.upper_total_foc)
        setupper_wave_direction(res.data.datachannel.upper_wave_direction)
        setupper_wave_height(res.data.datachannel.upper_wave_height)
        setupper_wave_period(res.data.datachannel.upper_wave_period)
        setupper_wind_direction(res.data.datachannel.upper_wind_direction)
        setupper_wind_speed(res.data.datachannel.upper_wind_speed)

        setimoTemp(res.data.datachannel.imo)
        setrevTemp(res.data.datachannel.rev)
        setlower_beaufortTemp(res.data.datachannel.lower_beaufort)
        setlower_boiler_focTemp(res.data.datachannel.lower_boiler_foc)
        setlower_courseTemp(res.data.datachannel.lower_course)
        setlower_displacementTemp(res.data.datachannel.lower_displacement)
        setlower_dwtTemp(res.data.datachannel.lower_dwt)
        setlower_ge_focTemp(res.data.datachannel.lower_ge_foc)
        setlower_gtTemp(res.data.datachannel.lower_gt)
        setlower_latTemp(res.data.datachannel.lower_lat)
        setlower_lngTemp(res.data.datachannel.lower_lng)
        setlower_log_distanceTemp(res.data.datachannel.lower_log_distance)
        setlower_log_speedTemp(res.data.datachannel.lower_log_speed)
        setlower_me_focTemp(res.data.datachannel.lower_me_foc)
        setlower_me_loadTemp(res.data.datachannel.lower_me_load)
        setlower_me_rpmTemp(res.data.datachannel.lower_me_rpm)
        setlower_og_distanceTemp(res.data.datachannel.lower_og_distance)
        setlower_og_speedTemp(res.data.datachannel.lower_og_speed)
        setlower_swell_directionTemp(res.data.datachannel.lower_swell_direction)
        setlower_swell_heightTemp(res.data.datachannel.lower_swell_height)
        setlower_swell_periodTemp(res.data.datachannel.lower_swell_period)
        setlower_total_focTemp(res.data.datachannel.lower_total_foc)
        setlower_wave_directionTemp(res.data.datachannel.lower_wave_direction)
        setlower_wave_heightTemp(res.data.datachannel.lower_wave_height)
        setlower_wave_periodTemp(res.data.datachannel.lower_wave_period)
        setlower_wind_directionTemp(res.data.datachannel.lower_wind_direction)
        setlower_wind_speedTemp(res.data.datachannel.lower_wind_speed)
        setupper_beaufortTemp(res.data.datachannel.upper_beaufort)
        setupper_boiler_focTemp(res.data.datachannel.upper_boiler_foc)
        setupper_courseTemp(res.data.datachannel.upper_course)
        setupper_displacementTemp(res.data.datachannel.upper_displacement)
        setupper_dwtTemp(res.data.datachannel.upper_dwt)
        setupper_ge_focTemp(res.data.datachannel.upper_ge_foc)
        setupper_gtTemp(res.data.datachannel.upper_gt)
        setupper_latTemp(res.data.datachannel.upper_lat)
        setupper_lngTemp(res.data.datachannel.upper_lng)
        setupper_log_distanceTemp(res.data.datachannel.upper_log_distance)
        setupper_log_speedTemp(res.data.datachannel.upper_log_speed)
        setupper_me_focTemp(res.data.datachannel.upper_me_foc)
        setupper_me_loadTemp(res.data.datachannel.upper_me_load)
        setupper_me_rpmTemp(res.data.datachannel.upper_me_rpm)
        setupper_og_distanceTemp(res.data.datachannel.upper_og_distance)
        setupper_og_speedTemp(res.data.datachannel.upper_og_speed)
        setupper_swell_directionTemp(res.data.datachannel.upper_swell_direction)
        setupper_swell_heightTemp(res.data.datachannel.upper_swell_height)
        setupper_swell_periodTemp(res.data.datachannel.upper_swell_period)
        setupper_total_focTemp(res.data.datachannel.upper_total_foc)
        setupper_wave_directionTemp(res.data.datachannel.upper_wave_direction)
        setupper_wave_heightTemp(res.data.datachannel.upper_wave_height)
        setupper_wave_periodTemp(res.data.datachannel.upper_wave_period)
        setupper_wind_directionTemp(res.data.datachannel.upper_wind_direction)
        setupper_wind_speedTemp(res.data.datachannel.upper_wind_speed)

        // Speed Consumption----------------------------------------------------------------------------------------------------------------------------
        setCPCurveName(res.data.cpcurve.name)
        setCPCurveA(res.data.cpcurve.a)
        setCPCurveAlpha(res.data.cpcurve.alpha)
        setCPCurveC(res.data.cpcurve.c)
        setCPCurveDisplay(res.data.cpcurve.display)
        setReferenceCurveName(res.data.rfcurve.name)
        setReferenceCurveA(res.data.rfcurve.a)
        setReferenceCurveAlpha(res.data.rfcurve.alpha)
        setReferenceCurveC(res.data.rfcurve.c)
        setReferenceCurveDisplay(res.data.rfcurve.display)
        setOtherCurve1Name(res.data.spcurve1.name)
        setOtherCurve1A(res.data.spcurve1.a)
        setOtherCurve1Alpha(res.data.spcurve1.alpha)
        setOtherCurve1C(res.data.spcurve1.c)
        setOtherCurve1Display(res.data.spcurve1.display)
        setOtherCurve2Name(res.data.spcurve2.name)
        setOtherCurve2A(res.data.spcurve2.a)
        setOtherCurve2Alpha(res.data.spcurve2.alpha)
        setOtherCurve2C(res.data.spcurve2.c)
        setOtherCurve2Display(res.data.spcurve2.display)
        setOtherCurve3Name(res.data.spcurve3.name)
        setOtherCurve3A(res.data.spcurve3.a)
        setOtherCurve3Alpha(res.data.spcurve3.alpha)
        setOtherCurve3C(res.data.spcurve3.c)
        setOtherCurve3Display(res.data.spcurve3.display)
        setOtherCurve4Name(res.data.spcurve4.name)
        setOtherCurve4A(res.data.spcurve4.a)
        setOtherCurve4Alpha(res.data.spcurve4.alpha)
        setOtherCurve4C(res.data.spcurve4.c)
        setOtherCurve4Display(res.data.spcurve4.display)

        setCPCurveNameTemp(res.data.cpcurve.name)
        setCPCurveATemp(res.data.cpcurve.a)
        setCPCurveAlphaTemp(res.data.cpcurve.alpha)
        setCPCurveCTemp(res.data.cpcurve.c)
        setCPCurveDisplayTemp(res.data.cpcurve.display)
        setReferenceCurveNameTemp(res.data.rfcurve.name)
        setReferenceCurveATemp(res.data.rfcurve.a)
        setReferenceCurveAlphaTemp(res.data.rfcurve.alpha)
        setReferenceCurveCTemp(res.data.rfcurve.c)
        setReferenceCurveDisplayTemp(res.data.rfcurve.display)
        setOtherCurve1NameTemp(res.data.spcurve1.name)
        setOtherCurve1ATemp(res.data.spcurve1.a)
        setOtherCurve1AlphaTemp(res.data.spcurve1.alpha)
        setOtherCurve1CTemp(res.data.spcurve1.c)
        setOtherCurve1DisplayTemp(res.data.spcurve1.display)
        setOtherCurve2NameTemp(res.data.spcurve2.name)
        setOtherCurve2ATemp(res.data.spcurve2.a)
        setOtherCurve2AlphaTemp(res.data.spcurve2.alpha)
        setOtherCurve2CTemp(res.data.spcurve2.c)
        setOtherCurve2DisplayTemp(res.data.spcurve2.display)
        setOtherCurve3NameTemp(res.data.spcurve3.name)
        setOtherCurve3ATemp(res.data.spcurve3.a)
        setOtherCurve3AlphaTemp(res.data.spcurve3.alpha)
        setOtherCurve3CTemp(res.data.spcurve3.c)
        setOtherCurve3DisplayTemp(res.data.spcurve3.display)
        setOtherCurve4NameTemp(res.data.spcurve4.name)
        setOtherCurve4ATemp(res.data.spcurve4.a)
        setOtherCurve4AlphaTemp(res.data.spcurve4.alpha)
        setOtherCurve4CTemp(res.data.spcurve4.c)
        setOtherCurve4DisplayTemp(res.data.spcurve4.display)

      })
        .catch((e: AxiosError<ErrorData>) => {
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

  // VesselMaster取得--------------------------------------------------------------------
  const getVesselMaster = (url, jwt) => {

    if (SeachInfo.imoNo !== "") {
      const formData = new FormData();
      formData.append("imo", SeachInfo.imoNo);
      formData.append("get", "get");
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': jwt,
        }
      }
      console.log("url[getVesselMaster]:", url);
      axios.post(url, formData, options).then((res) => {
        console.log("axios[getVesselMaster]:", res);

        // 認可チェック
        if (res.data === 401) {
          setAuthCheck(true)
          setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          setAuthCheck(true)
          setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          setAuthCheck(false)
        }

        // VesselMaster
        setImo(res.data.vesselmaster.Imo)
        setBallast(res.data.vesselmaster.Ballast)
        setBuildDate(res.data.vesselmaster.BuildDate)
        setDeadweight(res.data.vesselmaster.Deadweight)
        setGrosstongue(res.data.vesselmaster.Grosstongue)
        setLaden(res.data.vesselmaster.Laden)
        setLastDockDate(res.data.vesselmaster.LastDockDate)
        setHullCleaningDate(res.data.vesselmaster.HullCleaningDate)
        setOilType(res.data.vesselmaster.OilType)
        setOwner(res.data.vesselmaster.Owner)
        setSize(res.data.vesselmaster.Size)
        setVesselName(res.data.vesselmaster.VesselName)
        setVesselType(res.data.vesselmaster.VesselType)

        setImoTemp(res.data.vesselmaster.Imo)
        setBallastTemp(res.data.vesselmaster.Ballast)
        setBuildDateTemp(res.data.vesselmaster.BuildDate)
        setDeadweightTemp(res.data.vesselmaster.Deadweight)
        setGrosstongueTemp(res.data.vesselmaster.Grosstongue)
        setLadenTemp(res.data.vesselmaster.Laden)
        setLastDockDateTemp(res.data.vesselmaster.LastDockDate)
        setHullCleaningDateTemp(res.data.vesselmaster.HullCleaningDate)
        setOilTypeTemp(res.data.vesselmaster.OilType)
        setOwnerTemp(res.data.vesselmaster.Owner)
        setSizeTemp(res.data.vesselmaster.Size)
        setVesselNameTemp(res.data.vesselmaster.VesselName)
        setVesselTypeTemp(res.data.vesselmaster.VesselType)

        // DataChannel
        setimo(res.data.datachannel.imo)
        setrev(res.data.datachannel.rev)
        setlower_beaufort(res.data.datachannel.lower_beaufort)
        setlower_boiler_foc(res.data.datachannel.lower_boiler_foc)
        setlower_course(res.data.datachannel.lower_course)
        setlower_displacement(res.data.datachannel.lower_displacement)
        setlower_dwt(res.data.datachannel.lower_dwt)
        setlower_ge_foc(res.data.datachannel.lower_ge_foc)
        setlower_gt(res.data.datachannel.lower_gt)
        setlower_lat(res.data.datachannel.lower_lat)
        setlower_lng(res.data.datachannel.lower_lng)
        setlower_log_distance(res.data.datachannel.lower_log_distance)
        setlower_log_speed(res.data.datachannel.lower_log_speed)
        setlower_me_foc(res.data.datachannel.lower_me_foc)
        setlower_me_load(res.data.datachannel.lower_me_load)
        setlower_me_rpm(res.data.datachannel.lower_me_rpm)
        setlower_og_distance(res.data.datachannel.lower_og_distance)
        setlower_og_speed(res.data.datachannel.lower_og_speed)
        setlower_swell_direction(res.data.datachannel.lower_swell_direction)
        setlower_swell_height(res.data.datachannel.lower_swell_height)
        setlower_swell_period(res.data.datachannel.lower_swell_period)
        setlower_total_foc(res.data.datachannel.lower_total_foc)
        setlower_wave_direction(res.data.datachannel.lower_wave_direction)
        setlower_wave_height(res.data.datachannel.lower_wave_height)
        setlower_wave_period(res.data.datachannel.lower_wave_period)
        setlower_wind_direction(res.data.datachannel.lower_wind_direction)
        setlower_wind_speed(res.data.datachannel.lower_wind_speed)
        setupper_beaufort(res.data.datachannel.upper_beaufort)
        setupper_boiler_foc(res.data.datachannel.upper_boiler_foc)
        setupper_course(res.data.datachannel.upper_course)
        setupper_displacement(res.data.datachannel.upper_displacement)
        setupper_dwt(res.data.datachannel.upper_dwt)
        setupper_ge_foc(res.data.datachannel.upper_ge_foc)
        setupper_gt(res.data.datachannel.upper_gt)
        setupper_lat(res.data.datachannel.upper_lat)
        setupper_lng(res.data.datachannel.upper_lng)
        setupper_log_distance(res.data.datachannel.upper_log_distance)
        setupper_log_speed(res.data.datachannel.upper_log_speed)
        setupper_me_foc(res.data.datachannel.upper_me_foc)
        setupper_me_load(res.data.datachannel.upper_me_load)
        setupper_me_rpm(res.data.datachannel.upper_me_rpm)
        setupper_og_distance(res.data.datachannel.upper_og_distance)
        setupper_og_speed(res.data.datachannel.upper_og_speed)
        setupper_swell_direction(res.data.datachannel.upper_swell_direction)
        setupper_swell_height(res.data.datachannel.upper_swell_height)
        setupper_swell_period(res.data.datachannel.upper_swell_period)
        setupper_total_foc(res.data.datachannel.upper_total_foc)
        setupper_wave_direction(res.data.datachannel.upper_wave_direction)
        setupper_wave_height(res.data.datachannel.upper_wave_height)
        setupper_wave_period(res.data.datachannel.upper_wave_period)
        setupper_wind_direction(res.data.datachannel.upper_wind_direction)
        setupper_wind_speed(res.data.datachannel.upper_wind_speed)

        setimoTemp(res.data.datachannel.imo)
        setrevTemp(res.data.datachannel.rev)
        setlower_beaufortTemp(res.data.datachannel.lower_beaufort)
        setlower_boiler_focTemp(res.data.datachannel.lower_boiler_foc)
        setlower_courseTemp(res.data.datachannel.lower_course)
        setlower_displacementTemp(res.data.datachannel.lower_displacement)
        setlower_dwtTemp(res.data.datachannel.lower_dwt)
        setlower_ge_focTemp(res.data.datachannel.lower_ge_foc)
        setlower_gtTemp(res.data.datachannel.lower_gt)
        setlower_latTemp(res.data.datachannel.lower_lat)
        setlower_lngTemp(res.data.datachannel.lower_lng)
        setlower_log_distanceTemp(res.data.datachannel.lower_log_distance)
        setlower_log_speedTemp(res.data.datachannel.lower_log_speed)
        setlower_me_focTemp(res.data.datachannel.lower_me_foc)
        setlower_me_loadTemp(res.data.datachannel.lower_me_load)
        setlower_me_rpmTemp(res.data.datachannel.lower_me_rpm)
        setlower_og_distanceTemp(res.data.datachannel.lower_og_distance)
        setlower_og_speedTemp(res.data.datachannel.lower_og_speed)
        setlower_swell_directionTemp(res.data.datachannel.lower_swell_direction)
        setlower_swell_heightTemp(res.data.datachannel.lower_swell_height)
        setlower_swell_periodTemp(res.data.datachannel.lower_swell_period)
        setlower_total_focTemp(res.data.datachannel.lower_total_foc)
        setlower_wave_directionTemp(res.data.datachannel.lower_wave_direction)
        setlower_wave_heightTemp(res.data.datachannel.lower_wave_height)
        setlower_wave_periodTemp(res.data.datachannel.lower_wave_period)
        setlower_wind_directionTemp(res.data.datachannel.lower_wind_direction)
        setlower_wind_speedTemp(res.data.datachannel.lower_wind_speed)
        setupper_beaufortTemp(res.data.datachannel.upper_beaufort)
        setupper_boiler_focTemp(res.data.datachannel.upper_boiler_foc)
        setupper_courseTemp(res.data.datachannel.upper_course)
        setupper_displacementTemp(res.data.datachannel.upper_displacement)
        setupper_dwtTemp(res.data.datachannel.upper_dwt)
        setupper_ge_focTemp(res.data.datachannel.upper_ge_foc)
        setupper_gtTemp(res.data.datachannel.upper_gt)
        setupper_latTemp(res.data.datachannel.upper_lat)
        setupper_lngTemp(res.data.datachannel.upper_lng)
        setupper_log_distanceTemp(res.data.datachannel.upper_log_distance)
        setupper_log_speedTemp(res.data.datachannel.upper_log_speed)
        setupper_me_focTemp(res.data.datachannel.upper_me_foc)
        setupper_me_loadTemp(res.data.datachannel.upper_me_load)
        setupper_me_rpmTemp(res.data.datachannel.upper_me_rpm)
        setupper_og_distanceTemp(res.data.datachannel.upper_og_distance)
        setupper_og_speedTemp(res.data.datachannel.upper_og_speed)
        setupper_swell_directionTemp(res.data.datachannel.upper_swell_direction)
        setupper_swell_heightTemp(res.data.datachannel.upper_swell_height)
        setupper_swell_periodTemp(res.data.datachannel.upper_swell_period)
        setupper_total_focTemp(res.data.datachannel.upper_total_foc)
        setupper_wave_directionTemp(res.data.datachannel.upper_wave_direction)
        setupper_wave_heightTemp(res.data.datachannel.upper_wave_height)
        setupper_wave_periodTemp(res.data.datachannel.upper_wave_period)
        setupper_wind_directionTemp(res.data.datachannel.upper_wind_direction)
        setupper_wind_speedTemp(res.data.datachannel.upper_wind_speed)

        // Speed Consumption----------------------------------------------------------------------------------------------------------------------------
        setCPCurveName(res.data.cpcurve.name)
        setCPCurveA(res.data.cpcurve.a)
        setCPCurveAlpha(res.data.cpcurve.alpha)
        setCPCurveC(res.data.cpcurve.c)
        setCPCurveDisplay(res.data.cpcurve.display)
        setReferenceCurveName(res.data.rfcurve.name)
        setReferenceCurveA(res.data.rfcurve.a)
        setReferenceCurveAlpha(res.data.rfcurve.alpha)
        setReferenceCurveC(res.data.rfcurve.c)
        setReferenceCurveDisplay(res.data.rfcurve.display)
        setOtherCurve1Name(res.data.spcurve1.name)
        setOtherCurve1A(res.data.spcurve1.a)
        setOtherCurve1Alpha(res.data.spcurve1.alpha)
        setOtherCurve1C(res.data.spcurve1.c)
        setOtherCurve1Display(res.data.spcurve1.display)
        setOtherCurve2Name(res.data.spcurve2.name)
        setOtherCurve2A(res.data.spcurve2.a)
        setOtherCurve2Alpha(res.data.spcurve2.alpha)
        setOtherCurve2C(res.data.spcurve2.c)
        setOtherCurve2Display(res.data.spcurve2.display)
        setOtherCurve3Name(res.data.spcurve3.name)
        setOtherCurve3A(res.data.spcurve3.a)
        setOtherCurve3Alpha(res.data.spcurve3.alpha)
        setOtherCurve3C(res.data.spcurve3.c)
        setOtherCurve3Display(res.data.spcurve3.display)
        setOtherCurve4Name(res.data.spcurve4.name)
        setOtherCurve4A(res.data.spcurve4.a)
        setOtherCurve4Alpha(res.data.spcurve4.alpha)
        setOtherCurve4C(res.data.spcurve4.c)
        setOtherCurve4Display(res.data.spcurve4.display)

        setCPCurveNameTemp(res.data.cpcurve.name)
        setCPCurveATemp(res.data.cpcurve.a)
        setCPCurveAlphaTemp(res.data.cpcurve.alpha)
        setCPCurveCTemp(res.data.cpcurve.c)
        setCPCurveDisplayTemp(res.data.cpcurve.display)
        setReferenceCurveNameTemp(res.data.rfcurve.name)
        setReferenceCurveATemp(res.data.rfcurve.a)
        setReferenceCurveAlphaTemp(res.data.rfcurve.alpha)
        setReferenceCurveCTemp(res.data.rfcurve.c)
        setReferenceCurveDisplayTemp(res.data.rfcurve.display)
        setOtherCurve1NameTemp(res.data.spcurve1.name)
        setOtherCurve1ATemp(res.data.spcurve1.a)
        setOtherCurve1AlphaTemp(res.data.spcurve1.alpha)
        setOtherCurve1CTemp(res.data.spcurve1.c)
        setOtherCurve1DisplayTemp(res.data.spcurve1.display)
        setOtherCurve2NameTemp(res.data.spcurve2.name)
        setOtherCurve2ATemp(res.data.spcurve2.a)
        setOtherCurve2AlphaTemp(res.data.spcurve2.alpha)
        setOtherCurve2CTemp(res.data.spcurve2.c)
        setOtherCurve2DisplayTemp(res.data.spcurve2.display)
        setOtherCurve3NameTemp(res.data.spcurve3.name)
        setOtherCurve3ATemp(res.data.spcurve3.a)
        setOtherCurve3AlphaTemp(res.data.spcurve3.alpha)
        setOtherCurve3CTemp(res.data.spcurve3.c)
        setOtherCurve3DisplayTemp(res.data.spcurve3.display)
        setOtherCurve4NameTemp(res.data.spcurve4.name)
        setOtherCurve4ATemp(res.data.spcurve4.a)
        setOtherCurve4AlphaTemp(res.data.spcurve4.alpha)
        setOtherCurve4CTemp(res.data.spcurve4.c)
        setOtherCurve4DisplayTemp(res.data.spcurve4.display)

        setVesselTypeList(res.data.vessel_type_list)
        setOilTypeList(res.data.fuel_oil_type_list)

      })
        .catch((e: AxiosError<ErrorData>) => {
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  // VesselList取得--------------------------------------------------------------------
  const getVesselList = (url, jwt) => {

    if (jwt !== undefined) {
      const options = {
        headers: {
          'Authorization': jwt,
        }
      }
      console.log("url[getVesselList]:", url);
      axios.get(url, options).then((res) => {
        console.log("axios[getVesselList]", res);

        // 入力値保持
        console.log("localStorage[VesselList][getVesselList]", localStorage);
        console.log("SeachInfo[VesselList][getVesselList]", SeachInfo);

        setUser_id(res.data.datas.user_id)
        setGroup_id(res.data.datas.group_id)
        setCompany_id(res.data.datas.company_id)

        if (role !== undefined) {
          localStorage.clear()
          let Payload: payload
          Payload = {
            role: role,
            user_id: res.data.datas.user_id,
            group_id: res.data.datas.group_id,
            company_id: res.data.datas.company_id,
            gid: res.data.datas.gid,
            gidList: res.data.datas.gidList,
            imoList: res.data.datas.imoList,
            jwt: jwt
          }
          localStorage.setItem("payload", JSON.stringify(Payload))
        }

        // Loading解除
        setLoading(false);
        setSuccess(true);
      })
        .catch((e: AxiosError<ErrorData>) => {
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }

  }

  // タブ切り替え機能--------------------------------------------------------------------
  // 現在のアクティブなタブを管理するための状態
  const [activeTab, setActiveTab] = React.useState(0);

  // タブがクリックされた時に呼び出されるハンドラー関数
  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  return (
    <>
      <Box height="100vh" sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* useId有効期限切れダイアログ */}
        <Dialog
          open={userCheck}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",  // Set your width here
                height: "100%",
                maxHeight: "210px",  // Set your width here
              },
            },
          }}
        >
          <DialogTitle
            sx={{
              bgcolor: "#9ea2ad",
              height: "50px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            UserId Update
          </DialogTitle>
          <DialogContent
            sx={{
              background: "linear-gradient(#40475d, #0d1532)",
              height: "100px",
            }}>
            <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
              {message === "" ? "" : message}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ height: "50px", bgcolor: "#9ea2ad" }}>
            <Button
              sx={{
                marginLeft: "0px",
                marginRight: "-8px",
                backgroundColor: "#e66300",
                width: "150px",
                height: "50px",
                padding: "0",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "white",
                borderRadius: "0px",
                borderBottomRightRadius: "2px",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              onClick={handleClickWADATSUMI}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* 認可チェックダイアログ */}
        <Dialog
          open={authCheck}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",  // Set your width here
                height: "100%",
                maxHeight: "210px",  // Set your width here
              },
            },
          }}
        >
          <DialogTitle
            sx={{
              bgcolor: "#9ea2ad",
              height: "50px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            Auth check
          </DialogTitle>
          <DialogContent
            sx={{
              background: "linear-gradient(#40475d, #0d1532)",
              height: "100px",
            }}>
            <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
              {authMessage === "" ? "" : authMessage}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ height: "50px", bgcolor: "#9ea2ad" }}>
            <Button
              sx={{
                marginLeft: "0px",
                marginRight: "-8px",
                backgroundColor: "#e66300",
                width: "150px",
                height: "50px",
                padding: "0",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "white",
                borderRadius: "0px",
                borderBottomRightRadius: "2px",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              onClick={() => setAuthCheck(false)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* menu bar */}
        <AppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />

        {/* メイン部分 */}
        <Main>

          {/* ↓↓ タブ ヘッダー ↓↓ */}
          <Box sx={{ position: "relative", top: "0px", left: "0px", border: "0px solid #ccc", width: "1800px", height: "150px" }}>
            <Button
              sx={{
                position: "absolute",
                top: "10px",
                left: "100px",
                width: "248px",
                height: "35px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "rgba(255, 255, 255, 0.8)",
                backgroundColor: `${activeTab === 0 ? "#e66300" : "rgba(255, 255, 255, 0.5)"}`,
                textTransform: 'none',
                ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              }}
              onClick={() => handleTabClick(0)}
            >
              <Stack direction={"row"} sx={{}}>
                <Box sx={{ position: "absolute", top: "12px", left: "10px", borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                <Box sx={{ position: "absolute", top: "2px", left: "30px", }}>Vessel Information</Box>
              </Stack>
            </Button>
            <Button
              sx={{
                position: "absolute",
                top: "10px",
                left: "350px",
                width: "248px",
                height: "35px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "rgba(255, 255, 255, 0.8)",
                textTransform: 'none',
                backgroundColor: `${activeTab === 1 ? "#e66300" : "rgba(255, 255, 255, 0.5)"}`,
                ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              }}
              onClick={() => handleTabClick(1)}
            >
              <Stack direction={"row"} sx={{}}>
                <Box sx={{ position: "absolute", top: "12px", left: "10px", borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                <Box sx={{ position: "absolute", top: "2px", left: "30px", }}>Data Channel</Box>
              </Stack>
            </Button>
            <Button
              sx={{
                position: "absolute",
                top: "10px",
                left: "600px",
                width: "248px",
                height: "35px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "rgba(255, 255, 255, 0.8)",
                backgroundColor: `${activeTab === 2 ? "#e66300" : "rgba(255, 255, 255, 0.5)"}`,
                textTransform: 'none',
                ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              }}
              onClick={() => handleTabClick(2)}
            >
              <Stack direction={"row"} sx={{}}>
                <Box sx={{ position: "absolute", top: "12px", left: "10px", borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                <Box sx={{ position: "absolute", top: "2px", left: "30px", }}>Speed Consumption</Box>
              </Stack>
            </Button>

            <Stack
              direction={"row"}
              sx={{
                position: "absolute",
                top: "80px",
                left: "100px",
                width: "248px",
                height: "35px",
              }}>
              <Typography
                align="left"
                sx={{
                  position: "absolute",
                  top: "30px",
                  left: "0px",
                  color: 'white',
                  width: "100%",
                  fontSize: "14px",
                  fontFamily: "'Roboto Bold'",
                  fontWeight: '400',
                  lineHeight: "24px",
                  wordWrap: 'break-word',
                }}>
                Vessel Name
              </Typography>
              <ThemeProvider theme={SelectTheme}>
                <FormControl
                  sx={{
                    position: "absolute",
                    top: "20px",
                    left: "110px",
                  }}>
                  <Select
                    IconComponent={() =>
                      <div style={{ position: "absolute", top: "20px", left: "235px" }}>
                        <InputAdornment position="start" disablePointerEvents>
                          <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                        </InputAdornment>
                      </div>
                    }
                    MenuProps={{
                      MenuListProps: {
                        disablePadding: true,
                      },
                      style: { zIndex: 100000, }
                    }}
                    sx={{
                      width: 265,
                      height: 45,
                      color: "white",
                      ":hover": { backgroundColor: "#7a86a0" },
                      borderRadius: 30,
                    }}
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    label="range"
                    input={<BootstrapInput />}
                    onChange={selectImo}
                    value={SeachInfo.imoNo}
                  >
                    {SeachInfo.imoList.map((imo) => {
                      return (
                        <MenuItem value={imo.imoNo}>{imo.VesselName}</MenuItem>
                      )
                    }
                    )}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </Stack>
          </Box>
          {/* ↑↑ タブ ヘッダー ↑↑ */}

          {success ?
            <>
              {/* ↓↓ アクティブなタブのコンテンツを表示 ↓↓ */}
              <Box sx={{ position: "relative", top: "0px", left: "0px", border: "0px solid #ccc", width: "1800px", height: "650px" }}>

                <Box sx={{ position: "absolute", top: "px", left: "100px" }}>

                  {/* ↓↓ Vessel Information ↓↓ */}
                  {activeTab === 0 &&
                    <>
                      {/* Table */}
                      <div id="MasterInput-fixedTableArea">

                        {/* Header */}
                        {/* <div id="MasterInput-topRightArea">
                      <table id="MasterInput-topRightTable" className="MasterInput-fixedTable" >
                        <tbody>
                          <Box sx={{ position: "relative", height: "50px", width: "200px" }}>
                            <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>Vessel Master</Typography>
                          </Box>
                        </tbody>
                      </table>
                    </div> */}

                        {/* Body */}
                        <div id="MasterInput-bottomRightArea">
                          <table id="MasterInput-bottomRightTable" className="MasterInput-fixedTable">
                            <tbody>
                              <Box sx={{ position: "relative", height: "50px" }}>
                                <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>Vessel Information</Typography>
                              </Box>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          imo
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          {SeachInfo.imoNo}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          VesselName
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} />
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={VesselName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeVesselName(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          VesselType
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} />
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <ThemeProvider theme={SelectTheme}>
                                          <FormControl
                                            sx={{
                                              position: "relative",
                                            }}>
                                            <Select
                                              IconComponent={() =>
                                                <div style={{ position: "absolute", top: "20px", left: "125px" }}>
                                                  <InputAdornment position="start" disablePointerEvents>
                                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                                  </InputAdornment>
                                                </div>
                                              }
                                              MenuProps={{
                                                MenuListProps: {
                                                  disablePadding: true,
                                                },
                                                style: { zIndex: 100000, }
                                              }}
                                              sx={{
                                                width: 150,
                                                height: 45,
                                                color: "white",
                                                ":hover": { backgroundColor: "#7a86a0" },
                                                borderRadius: 30,
                                              }}
                                              labelId="demo-controlled-open-select-label"
                                              id="demo-controlled-open-select"
                                              label="range"
                                              input={<BootstrapInput />}
                                              onChange={handleChanegeVesselType}
                                              value={VesselType}
                                            >
                                              {
                                                VesselTypeList?.map((type) => {
                                                  return (
                                                    <MenuItem value={type.type.S}>{type.type_name.S}</MenuItem>
                                                  )
                                                }
                                                )
                                              }
                                            </Select>
                                          </FormControl>
                                        </ThemeProvider>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          OilType
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} />
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <ThemeProvider theme={SelectTheme}>
                                          <FormControl
                                            sx={{
                                              position: "relative",
                                            }}>
                                            <Select
                                              IconComponent={() =>
                                                <div style={{ position: "absolute", top: "20px", left: "125px" }}>
                                                  <InputAdornment position="start" disablePointerEvents>
                                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                                  </InputAdornment>
                                                </div>
                                              }
                                              MenuProps={{
                                                MenuListProps: {
                                                  disablePadding: true,
                                                },
                                                style: { zIndex: 100000, }
                                              }}
                                              sx={{
                                                width: 150,
                                                height: 45,
                                                color: "white",
                                                ":hover": { backgroundColor: "#7a86a0" },
                                                borderRadius: 30,
                                              }}
                                              labelId="demo-controlled-open-select-label"
                                              id="demo-controlled-open-select"
                                              label="range"
                                              input={<BootstrapInput />}
                                              onChange={handleChanegeOilType}
                                              value={OilType}
                                            >
                                              {
                                                OilTypeList?.map((type) => {
                                                  return (
                                                    <MenuItem value={type.fuel_oil_type.S}>{type.fuel_oil_type_name.S}</MenuItem>
                                                  )
                                                }
                                                )
                                              }
                                            </Select>
                                          </FormControl>
                                        </ThemeProvider>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          DWT
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} />
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={Deadweight} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeDeadweight(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          GT
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} />
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={Grosstongue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeGrosstongue(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Ballast
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} />
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={Ballast} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeBallast(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Laden
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} />
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={Laden} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeLaden(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Size
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} />
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={Size} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeSize(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Build Date
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={BuildDate} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeBuildDate(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Hull Cleaning Date
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={HullCleaningDate} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeHullCleaningDate(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          LastDock Date
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={LastDockDate} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeLastDockDate(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Owner
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={Owner} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOwner(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>}
                  {/* ↑↑ Vessel Information ↑↑ */}

                  {/* ↓↓ Data Channel ↓↓ */}
                  {activeTab === 1 &&
                    <>
                      {/* Table */}
                      <div id="MasterInput-fixedTableArea">

                        {/* Header */}
                        {/* <div id="MasterInput-topRightArea">
                      <table id="MasterInput-topRightTable" className="MasterInput-fixedTable" >
                        <tbody>
                          <Box sx={{ position: "relative", height: "50px", width: "200px" }}>
                            <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>Vessel Master</Typography>
                          </Box>
                        </tbody>
                      </table>
                    </div> */}

                        {/* Body */}
                        <div id="MasterInput-bottomRightArea">
                          <table id="MasterInput-bottomRightTable" className="MasterInput-fixedTable">
                            <tbody>
                              <Box sx={{ position: "relative", height: "50px" }}>
                                <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                  Data Channel
                                </Typography>
                              </Box>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          imo
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          {SeachInfo.imoNo}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          VesselName
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          {VesselName}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          rev
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          {rev}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={8}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_beaufort
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_beaufort} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_beaufort(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_beaufort
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_beaufort} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_beaufort(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_boiler_foc
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_boiler_foc} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_boiler_foc(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_boiler_foc
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_boiler_foc} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_boiler_foc(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_course
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_course} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_course(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_course
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_course} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_course(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_displacement
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_displacement} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_displacement(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_displacement
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_displacement} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_displacement(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_dwt
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_dwt} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_dwt(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_dwt
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_dwt} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_dwt(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_ge_foc
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_ge_foc} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_ge_foc(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_ge_foc
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_ge_foc} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_ge_foc(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_gt
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_gt} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_gt(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_gt
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_gt} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_gt(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_lat
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_lat} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_lat(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_lat
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_lat} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_lat(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_lng
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_lng} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_lng(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_lng
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_lng} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_lng(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_log_distance
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_log_distance} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_log_distance(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_log_distance
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_log_distance} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_log_distance(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_log_speed
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_log_speed} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_log_speed(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_log_speed
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_log_speed} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_log_speed(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_me_foc
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_me_foc} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_me_foc(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_me_foc
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_me_foc} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_me_foc(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_me_load
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_me_load} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_me_load(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_me_load
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_me_load} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_me_load(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_me_rpm
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_me_rpm} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_me_rpm(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_me_rpm
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_me_rpm} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_me_rpm(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_og_distance
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_og_distance} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_og_distance(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_og_distance
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_og_distance} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_og_distance(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_og_speed
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_og_speed} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_og_speed(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_og_speed
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_og_speed} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_og_speed(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_swell_direction
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_swell_direction} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_swell_direction(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_swell_direction
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_swell_direction} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_swell_direction(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_swell_height
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_swell_height} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_swell_height(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_swell_height
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_swell_height} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_swell_height(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_swell_period
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_swell_period} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_swell_period(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_swell_period
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_swell_period} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_swell_period(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_total_foc
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_total_foc} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_total_foc(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_total_foc
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_total_foc} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_total_foc(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_wave_direction
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_wave_direction} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_wave_direction(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_wave_direction
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_wave_direction} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_wave_direction(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_wave_height
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_wave_height} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_wave_height(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_wave_height
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_wave_height} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_wave_height(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_wave_period
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_wave_period} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_wave_period(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_wave_period
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_wave_period} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_wave_period(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_wind_direction
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_wind_direction} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_wind_direction(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_wind_direction
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_wind_direction} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_wind_direction(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          lower_wind_speed
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={lower_wind_speed} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegelower_wind_speed(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={1}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          upper_wind_speed
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Box sx={{ position: "relative" }}>
                                          {/* <StarIcon sx={{ position: "absolute", top: "-25px", left: "130px", color: "red", width: "10px", height: "10px" }} /> */}
                                        </Box>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={upper_wind_speed} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeupper_wind_speed(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0}>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  }
                  {/* ↑↑ Data Channel ↑↑ */}

                  {/* ↓↓ Speed Consumption ↓↓ */}
                  {activeTab === 2 &&
                    <>
                      {/* Table */}
                      <div id="MasterInput-fixedTableArea-sp">

                        {/* Header */}
                        <div id="MasterInput-topRightArea-sp">
                          <table id="MasterInput-topRightTable-sp" className="MasterInput-fixedTable" >
                            <tbody>
                              <Box sx={{ position: "relative", height: "50px", width: "600px" }}>
                                <Stack direction={"row"}>
                                  <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    Speed Consuption Curve Setting :
                                  </Typography>
                                  <Typography align="left" sx={{ position: "absolute", top: "20px", left: "320px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '700', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    y = alpha*xª + C
                                  </Typography>
                                </Stack>
                              </Box>
                            </tbody>
                          </table>
                        </div>

                        {/* Body */}
                        <div id="MasterInput-bottomRightArea-sp">
                          <table id="MasterInput-bottomRightTable-sp" className="MasterInput-fixedTable">
                            <tbody>
                              <Box sx={{ position: "relative", height: "50px" }}>
                                <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                  CP Curve
                                </Typography>
                              </Box>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          name
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          {CPCurveName}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          alpha
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={CPCurveAlpha} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeCPCurveAlpha(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          a
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={CPCurveA} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeCPCurveA(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          C
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={CPCurveC} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeCPCurveC(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          display
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          ON
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr></tr>
                              <Box sx={{ height: "20px" }}></Box>
                              <Box sx={{ position: "relative", height: "50px" }}>
                                <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                  Reference Curve
                                </Typography>
                              </Box>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          name
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          {ReferenceCurveName}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          alpha
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={ReferenceCurveAlpha} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeReferenceCurveAlpha(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          a
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={ReferenceCurveA} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeReferenceCurveA(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          C
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={ReferenceCurveC} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeReferenceCurveC(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Display
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          ON
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr></tr>
                              <Box sx={{ height: "20px" }}></Box>
                              <Box sx={{ position: "relative", height: "50px" }}>
                                <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                  Other Curve1
                                </Typography>
                              </Box>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          name
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve1Name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve1Name(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          alpha
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve1Alpha} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve1Alpha(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          a
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve1A} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve1A(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          C
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve1C} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve1C(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Display
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <ThemeProvider theme={SelectTheme}>
                                          <FormControl>
                                            <Select
                                              IconComponent={() =>
                                                <div style={{ position: "absolute", top: "20px", left: "125px" }}>
                                                  <InputAdornment position="start" disablePointerEvents>
                                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                                  </InputAdornment>
                                                </div>
                                              }
                                              MenuProps={{
                                                MenuListProps: {
                                                  disablePadding: true,
                                                },
                                                style: { zIndex: 100000, }
                                              }}
                                              sx={{
                                                width: "150px",
                                                height: "45px",
                                                color: "white",
                                                ":hover": { backgroundColor: "#7a86a0" },
                                                borderRadius: "30px",
                                              }}
                                              labelId="demo-controlled-open-select-label"
                                              id="demo-controlled-open-select"
                                              label="range"
                                              input={<BootstrapInput />}
                                              onChange={handleChanegeOtherCurve1Display}
                                              value={OtherCurve1Display}
                                            >
                                              <MenuItem value={"1"}>ON</MenuItem>
                                              <MenuItem value={"0"}>OFF</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </ThemeProvider>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr></tr>
                              <Box sx={{ height: "20px" }}></Box>
                              <Box sx={{ position: "relative", height: "50px" }}>
                                <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                  Other Curve2
                                </Typography>
                              </Box>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          name
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve2Name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve2Name(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          alpha
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve2Alpha} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve2Alpha(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          a
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve2A} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve2A(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          C
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve2C} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve2C(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Display
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <ThemeProvider theme={SelectTheme}>
                                          <FormControl>
                                            <Select
                                              IconComponent={() =>
                                                <div style={{ position: "absolute", top: "20px", left: "125px" }}>
                                                  <InputAdornment position="start" disablePointerEvents>
                                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                                  </InputAdornment>
                                                </div>
                                              }
                                              MenuProps={{
                                                MenuListProps: {
                                                  disablePadding: true,
                                                },
                                                style: { zIndex: 100000, }
                                              }}
                                              sx={{
                                                width: "150px",
                                                height: "45px",
                                                color: "white",
                                                ":hover": { backgroundColor: "#7a86a0" },
                                                borderRadius: "30px",
                                              }}
                                              labelId="demo-controlled-open-select-label"
                                              id="demo-controlled-open-select"
                                              label="range"
                                              input={<BootstrapInput />}
                                              onChange={handleChanegeOtherCurve2Display}
                                              value={OtherCurve2Display}
                                            >
                                              <MenuItem value={"1"}>ON</MenuItem>
                                              <MenuItem value={"0"}>OFF</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </ThemeProvider>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr></tr>
                              <Box sx={{ height: "20px" }}></Box>
                              <Box sx={{ position: "relative", height: "50px" }}>
                                <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                  Other Curve3
                                </Typography>
                              </Box>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          name
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve3Name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve3Name(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          alpha
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve3Alpha} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve3Alpha(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          a
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve3A} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve3A(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          C
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve3C} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve3C(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          Display
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <ThemeProvider theme={SelectTheme}>
                                          <FormControl>
                                            <Select
                                              IconComponent={() =>
                                                <div style={{ position: "absolute", top: "20px", left: "125px" }}>
                                                  <InputAdornment position="start" disablePointerEvents>
                                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                                  </InputAdornment>
                                                </div>
                                              }
                                              MenuProps={{
                                                MenuListProps: {
                                                  disablePadding: true,
                                                },
                                                style: { zIndex: 100000, }
                                              }}
                                              sx={{
                                                width: "150px",
                                                height: "45px",
                                                color: "white",
                                                ":hover": { backgroundColor: "#7a86a0" },
                                                borderRadius: "30px",
                                              }}
                                              labelId="demo-controlled-open-select-label"
                                              id="demo-controlled-open-select"
                                              label="range"
                                              input={<BootstrapInput />}
                                              onChange={handleChanegeOtherCurve3Display}
                                              value={OtherCurve3Display}
                                            >
                                              <MenuItem value={"1"}>ON</MenuItem>
                                              <MenuItem value={"0"}>OFF</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </ThemeProvider>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                              <tr></tr>
                              <Box sx={{ height: "20px" }}></Box>
                              <Box sx={{ position: "relative", height: "50px" }}>
                                <Typography align="left" sx={{ position: "absolute", top: "20px", left: "0px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 18, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                  Other Curve4
                                </Typography>
                              </Box>
                              <tr>
                                <td className='cell'>
                                  <Stack direction={"row"} sx={{ width: "100%", marginLeft: "20px" }}>
                                    <Grid container sx={{ width: "100%" }} alignItems={"center"}>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          name
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={2}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve4Name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve4Name(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          alpha
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve4Alpha} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve4Alpha(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          a
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve4A} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve4A(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          C
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <FormControl>
                                          <Input
                                            value={OtherCurve4C} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChanegeOtherCurve4C(event.target.value) }}
                                            sx={{
                                              fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Grid>
                                      <Grid xs={0.25}>
                                      </Grid>
                                      <Grid xs={0.5}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          display
                                        </Typography>
                                      </Grid>
                                      <Grid xs={0.25}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                          :
                                        </Typography>
                                      </Grid>
                                      <Grid xs={1}>
                                        <ThemeProvider theme={SelectTheme}>
                                          <FormControl>
                                            <Select
                                              IconComponent={() =>
                                                <div style={{ position: "absolute", top: "20px", left: "125px" }}>
                                                  <InputAdornment position="start" disablePointerEvents>
                                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                                  </InputAdornment>
                                                </div>
                                              }
                                              MenuProps={{
                                                MenuListProps: {
                                                  disablePadding: true,
                                                },
                                                style: { zIndex: 100000, }
                                              }}
                                              sx={{
                                                width: "150px",
                                                height: "45px",
                                                color: "white",
                                                ":hover": { backgroundColor: "#7a86a0" },
                                                borderRadius: "30px",
                                              }}
                                              labelId="demo-controlled-open-select-label"
                                              id="demo-controlled-open-select"
                                              label="range"
                                              input={<BootstrapInput />}
                                              onChange={handleChanegeOtherCurve4Display}
                                              value={OtherCurve4Display}
                                            >
                                              <MenuItem value={"1"}>ON</MenuItem>
                                              <MenuItem value={"0"}>OFF</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </ThemeProvider>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  }
                  {/* ↑↑ Speed Consumption ↑↑ */}

                </Box>
              </Box>
              {/* ↑↑ アクティブなタブのコンテンツを表示 ↑↑ */}
            </>
            :
            <Box sx={{ position: "relative", top: "0px", left: "0px", border: "0px solid #ccc", width: "1800px", height: "650px" }}>
              {loading && (
                <CircularProgress
                  size={68}
                  sx={{
                    color: "blue",
                    position: 'absolute',
                    top: "150px",
                    left: "500px",
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
          }


          {/* ↓↓ 実行ボタン ↓↓ */}
          <Box>
            <Box sx={{ position: "relative", top: "0px", left: "0px", border: "0px solid #ccc", width: "1800px", height: "70px" }}>
              <Stack direction={"row"} sx={{ position: "relative" }}>
                <Button
                  sx={{
                    position: "absolute",
                    top: "10px",
                    left: "700px",
                    width: "180px",
                    height: "40px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    fontSize: "18px",
                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                    fontWeight: "bold",
                    color: "rgba(255, 255, 255, 0.8)",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    ":hover": {
                      bgcolor: "rgba(255, 255, 255, 0.6)",
                      color: "rgba(255, 255, 255, 0.8)",
                    },
                    textTransform: 'none'
                  }}
                  onClick={handleClickOpenDialogCancel}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    position: "absolute",
                    top: "10px",
                    left: "900px",
                    width: "180px",
                    height: "40px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    fontSize: "18px",
                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                    fontWeight: "bold",
                    color: "rgba(255, 255, 255, 0.8)",
                    backgroundColor: "#e66300",
                    ":hover": {
                      bgcolor: "rgba(255, 255, 255, 0.6)",
                      color: "rgba(255, 255, 255, 0.8)",
                    },
                    textTransform: 'none'
                  }}
                  onClick={handleClickOpenDialogSave}
                >
                  Save
                </Button>
              </Stack>
            </Box>

            {/* ↓↓ Save Dialog ↓↓ */}
            <Dialog
              open={openDialogSave}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "400px",  // Set your width here
                    height: "100%",
                    maxHeight: "210px",  // Set your width here
                  },
                },
              }}
            >
              <DialogTitle
                sx={{
                  bgcolor: "#474C53",
                  height: "50px",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                Save
              </DialogTitle>
              <DialogContent
                sx={{
                  background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                  height: "80px",
                }}>
                <Typography align="center" sx={{ marginTop: "44px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
                  Are you sure to save?
                </Typography>
              </DialogContent>
              <DialogActions
                sx={{
                  height: "50px",
                  bgcolor: "#474C53"
                }}>
                <Button
                  sx={{
                    marginLeft: "0px",
                    marginRight: "-8px",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    width: "127px",
                    height: "50px",
                    padding: "0",
                    fontSize: "18px",
                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: "0px",
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                  onClick={handleNoClickOpenDialogSave}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    marginLeft: "0px",
                    marginRight: "-8px",
                    backgroundColor: "#e66300",
                    width: "150px",
                    height: "50px",
                    padding: "0",
                    fontSize: "18px",
                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: "0px",
                    borderBottomRightRadius: "2px",
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                  onClick={handleClickInsert}>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
            {/* ↑↑ Save Dialog ↑↑ */}

            {/* ↓↓ Cancel Dialog ↓↓ */}
            <Dialog
              open={openDialogCancel}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "400px",  // Set your width here
                    height: "100%",
                    maxHeight: "210px",  // Set your width here
                  },
                },
              }}
            >
              <DialogTitle
                sx={{
                  bgcolor: "#474C53",
                  height: "50px",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                Cancel
              </DialogTitle>
              <DialogContent
                sx={{
                  background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                  height: "80px",
                }}>
                <Typography align="center" sx={{ marginTop: "44px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
                  Are you sure to cancel?
                </Typography>
              </DialogContent>
              <DialogActions
                sx={{
                  height: "50px",
                  bgcolor: "#474C53"
                }}>
                <Button
                  sx={{
                    marginLeft: "0px",
                    marginRight: "-8px",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    width: "127px",
                    height: "50px",
                    padding: "0",
                    fontSize: "18px",
                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: "0px",
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                  onClick={handleNoClickOpenDialogCancel}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    marginLeft: "0px",
                    marginRight: "-8px",
                    backgroundColor: "#e66300",
                    width: "150px",
                    height: "50px",
                    padding: "0",
                    fontSize: "18px",
                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: "0px",
                    borderBottomRightRadius: "2px",
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                  onClick={handleClickReset}>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            {/* ↑↑ Cancel Dialog ↑↑ */}

          </Box>
          {/* ↑↑ 実行ボタン ↑↑ */}

        </Main >
      </Box >
    </>
  );
}